import {getAuthDataCall, postAuthDataCall, postAuthDataCall2} from "../utils";

export const getCheckout = async () => {
  const resp = await getAuthDataCall(`/auth/checkout`);
  return resp;
};

export const placeCheckout = async (payload) => {
  const resp = await postAuthDataCall2("/auth/orders?get_by=payment", payload);
  return resp;
};

export const promocode = async (payload) => {
  const resp = await postAuthDataCall("/auth/promoCode", payload);
  return resp;
};

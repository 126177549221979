import "../../assets/styles/quizzes.css";
import ourLogo from "../../assets/image/dawraty-logo.png";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../context/AuthContext";
import { OpenAI } from "openai";
import Swal from "sweetalert2";

import {
    getStudentThreads,
    putStudentThreads,
    getStudentCourse,
} from "../services/student";
import { getDataCall, getAuthDataCall } from "../services/utils";
import Cookies from "js-cookie";
import {
    FaRegThumbsUp,
    FaThumbsUp,
    FaRegThumbsDown,
    FaThumbsDown,
} from "react-icons/fa";
import {
    MdOutlineReportProblem,
    MdFavoriteBorder,
    MdFavorite,
} from "react-icons/md";
import {
    upvoteQuizQuestion,
    unupvoteQuizQuestion,
    downvoteQuizQuestion,
    undownvoteQuizQuestion,
    favoriteQuizQuestion,
    getQuizQuestions,
} from "../services/ai";
import { showSuccess, showError } from "../Utils/helper";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import ReportIssueModal from "../modals/ReportIssueModal";

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});

const assistant = await openai.beta.assistants.retrieve(
    process.env.REACT_APP_QUIZZES_ASSISTANT_ID
);

function Question({
    chapter_id,
    course_id,
    question,
    correct_answer,
    incorrect_answer1,
    incorrect_answer2,
    incorrect_answer3,
    explanation,
    quizScore,
    quizSubmited,
    quizComponent,
    index,
}) {
    const { user } = useContext(UserContext);

    const { t } = useTranslation();

    const [answers, setAnswers] = useState([
        correct_answer,
        incorrect_answer1,
        incorrect_answer2,
        incorrect_answer3,
    ]);
    const [answersShuffled, setAnswersShuffled] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState("");

    const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
    const [thumbsDownClicked, setThumbsDownClicked] = useState(false);
    const [favoriteClicked, setFavoriteClicked] = useState(false);
    const [show, setShow] = useState(false);

    // console.log(quizComponent);

    const [openReportIssueModal, closeReportIssueModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal
                isOpen
                ariaHideApp={false}
                className="react_modal"
                overlayClassName="modal_overlay"
            >
                <ReportIssueModal
                    closeModal={closeReportIssueModal}
                    questionInfo={{
                        course_id: course_id,
                        chapter_id: chapter_id,
                        user_id: user?.id,
                        question_id:
                            quizComponent.id === undefined
                                ? null
                                : quizComponent.id,
                        question: question,
                        answer: correct_answer,
                        incorrect_answer1: incorrect_answer1,
                        incorrect_answer2: incorrect_answer2,
                        incorrect_answer3: incorrect_answer3,
                        explanation: explanation,
                    }}
                    setShow={setShow}
                    type="ai_quiz"
                />
            </ReactModal>
        );
    });

    const handleThumbsUp = async (e) => {
        e.stopPropagation(); // Prevent the card flip event
        if (thumbsUpClicked) {
            // remove upvote
            const resp = await unupvoteQuizQuestion({
                course_id: course_id,
                chapter_id: chapter_id,
                question: question,
                answer: correct_answer,
                incorrect_answer1: incorrect_answer1,
                incorrect_answer2: incorrect_answer2,
                incorrect_answer3: incorrect_answer3,
                explanation: explanation,
            })
                .then((res) => {
                    setThumbsUpClicked(false);
                    showSuccess(t("alertMessage.success"));
                })
                .catch((err) => showError(t("alertMessage.wrong")));
        } else {
            const resp = await upvoteQuizQuestion({
                course_id: course_id,
                chapter_id: chapter_id,
                question: question,
                answer: correct_answer,
                is_downvoted: thumbsDownClicked === true ? true : false,
                incorrect_answer1: incorrect_answer1,
                incorrect_answer2: incorrect_answer2,
                incorrect_answer3: incorrect_answer3,
                explanation: explanation,
            })
                .then((res) => {
                    console.log(res);
                    setThumbsUpClicked(true);
                    setThumbsDownClicked(false);
                    showSuccess(t("alertMessage.success"));
                })
                .catch((err) => showError(t("alertMessage.wrong")));
        }
    };

    const handleThumbsDown = async (e) => {
        e.stopPropagation(); // Prevent the card flip event
        if (thumbsDownClicked) {
            // remove upvote
            const resp = await undownvoteQuizQuestion({
                course_id: course_id,
                chapter_id: chapter_id,
                question: question,
                answer: correct_answer,
                incorrect_answer1: incorrect_answer1,
                incorrect_answer2: incorrect_answer2,
                incorrect_answer3: incorrect_answer3,
                explanation: explanation,
            })
                .then((res) => {
                    setThumbsDownClicked(false);
                    showSuccess(t("alertMessage.success"));
                })
                .catch((err) => showError(t("alertMessage.wrong")));
        } else {
            const resp = await downvoteQuizQuestion({
                course_id: course_id,
                chapter_id: chapter_id,
                question: question,
                answer: correct_answer,
                is_upvoted: thumbsUpClicked === true ? true : false,
                incorrect_answer1: incorrect_answer1,
                incorrect_answer2: incorrect_answer2,
                incorrect_answer3: incorrect_answer3,
                explanation: explanation,
            })
                .then((res) => {
                    setThumbsDownClicked(true);
                    setThumbsUpClicked(false);
                    showSuccess(t("alertMessage.success"));
                })
                .catch((err) => showError(t("alertMessage.wrong")));
        }
    };

    const handleReportIssue = async (e) => {
        e.stopPropagation(); // Prevent the card flip event
        openReportIssueModal();
    };

    const handleFavorite = async (e) => {
        e.stopPropagation();
        setFavoriteClicked(!favoriteClicked);
        const resp = await favoriteQuizQuestion({
            course_id: course_id,
            chapter_id: chapter_id,
            user_id: user?.id,
            question: question,
            answer: correct_answer,
            incorrect_answer1: incorrect_answer1,
            incorrect_answer2: incorrect_answer2,
            incorrect_answer3: incorrect_answer3,
            explanation: explanation,
        })
            .then((res) => {
                showSuccess(t("alertMessage.success"));
            })
            .catch((err) => showError(t("alertMessage.wrong")));
    };

    const letters = ["A. ", "B. ", "C. ", "D. "];

    useEffect(() => {
        shuffleArray(answers);
    }, []);

    function shuffleArray(array) {
        let setter = array;
        for (var i = setter.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = setter[i];
            setter[i] = setter[j];
            setter[j] = temp;
        }
        setAnswers(setter);
        setAnswersShuffled(true);
    }

    function colorAnswer(answer) {
        if (selectedAnswer == answer && !quizSubmited)
            return { color: "#1b91ff" };

        if (
            quizSubmited &&
            answer != correct_answer &&
            selectedAnswer == answer
        )
            return { color: "red" };
        else if (quizSubmited && answer == correct_answer)
            return { color: "limegreen" };
    }

    function explanationStyles() {
        if (quizScore[index] == "correct")
            return {
                color: "limegreen",
                fontWeight: "bold",
                borderTop: "1px solid #0b4475",
                paddingTop: "20px",
            };
        else if (quizScore[index] == "incorrect")
            return {
                color: "red",
                fontWeight: "bold",
                borderTop: "1px solid #0b4475",
                paddingTop: "20px",
            };
    }

    return (
        <div className="quiz-question">
            <div className="quiz-question-title">
                <span
                    style={{
                        color: "#0b4475",
                        fontWeight: "bold",
                        paddingRight: "10px",
                    }}
                >
                    {index + 1}.
                </span>
                <span className="quiz-question-text">{question}</span>
            </div>
            {answersShuffled
                ? answers.map((answer, key) => (
                      <div
                          className="quiz-answer"
                          onClick={() => {
                              if (answer == correct_answer) {
                                  quizScore[index] = "correct";
                                  setSelectedAnswer(answer);
                              } else {
                                  quizScore[index] = "incorrect";
                                  setSelectedAnswer(answer);
                              }
                          }}
                      >
                          <span
                              className="quiz-answer-text"
                              style={colorAnswer(answer)}
                          >
                              {letters[key]}
                              {answer}
                          </span>
                          <br />
                      </div>
                  ))
                : null}
            {quizSubmited ? (
                <p className="question-explanation" style={explanationStyles()}>
                    Explanation: {explanation}
                </p>
            ) : null}
            {quizSubmited && (
                <div className="question-tools">
                    <button
                        onClick={handleFavorite}
                        style={{
                            color: "#0b4475",
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            fontSize: "24px",
                        }}
                    >
                        {favoriteClicked ? (
                            <MdFavorite />
                        ) : (
                            <MdFavoriteBorder />
                        )}
                    </button>
                    <button
                        onClick={handleThumbsUp}
                        style={{
                            color: "#0b4475",
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            fontSize: "24px",
                        }}
                    >
                        {thumbsUpClicked ? <FaThumbsUp /> : <FaRegThumbsUp />}
                    </button>
                    <button
                        onClick={handleThumbsDown}
                        style={{
                            color: "#0b4475",
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            fontSize: "24px",
                        }}
                    >
                        {thumbsDownClicked ? (
                            <FaThumbsDown />
                        ) : (
                            <FaRegThumbsDown />
                        )}
                    </button>
                    <br />
                    <button
                        onClick={handleReportIssue}
                        style={{
                            color: "#0b4475",
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            fontSize: "24px",
                        }}
                    >
                        <span>
                            <MdOutlineReportProblem />
                        </span>
                    </button>
                </div>
            )}
        </div>
    );
}

function GeneratedQuiz({
    course_id,
    chapter_id,
    quiz,
    setQuiz,
    quizScore,
    setQuizScore,
    createMessage,
    setLoading,
    quizSubmited,
    setQuizSubmitted,
}) {
    const [quizResults, setQuizResults] = useState({
        scorePercent: "",
        totalScore: "",
    });

    function shuffleArray(array) {
        let setter = array;
        for (var i = setter.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = setter[i];
            setter[i] = setter[j];
            setter[j] = temp;
        }
        setQuiz(setter);
    }

    function showScore() {
        let points = 0;
        let totalScore;

        if (quizScore.includes(null) == false) {
            for (let i = 0; i < quizScore.length; i++) {
                if (quizScore[i] == "correct") {
                    points += 1;
                }
            }

            totalScore = points / quizScore.length;
            let scorePercent =
                totalScore != 1.0
                    ? totalScore.toPrecision(2).replace("0.", "%")
                    : totalScore.toPrecision(2).replace("1.0", "100%");
            quizResults.scorePercent = scorePercent;
            quizResults.totalScore = `${points}/${quizScore.length}`;
            setQuizSubmitted(true);
            Swal.fire({
                title: `Final Score`,
                text: `${points}/${quizScore.length} ~ ${scorePercent}`,
                confirmButtonText: "Review",
                confirmButtonColor: "#0b4475",
                color: "#0b4475",
            });
        } else {
            Swal.fire({
                title: `Missing Answers!`,
                text: `Please answer all the questions before submitting...`,
                icon: "error",
                confirmButtonText: "Close",
                confirmButtonColor: "#0b4475",
                color: "#0b4475",
            });
        }
    }

    return (
        <div className="quiz-container">
            {quiz.map((quizComponent, key) => (
                <Question
                    chapter_id={chapter_id}
                    course_id={course_id}
                    question={quizComponent.question1}
                    correct_answer={quizComponent.correct_answer1}
                    incorrect_answer1={quizComponent.incorrect_answer1}
                    incorrect_answer2={quizComponent.incorrect_answer2}
                    incorrect_answer3={quizComponent.incorrect_answer3}
                    explanation={quizComponent.explanation}
                    quizScore={quizScore}
                    quizSubmited={quizSubmited}
                    quizComponent={quizComponent}
                    index={key}
                />
            ))}
            {!quizSubmited ? (
                <button
                    className="quiz-submit"
                    onClick={(e) => {
                        showScore();
                    }}
                >
                    Submit
                </button>
            ) : (
                <div className="quiz-results">
                    <button
                        className="new-quiz"
                        onClick={() => {
                            setQuiz([]);
                            setQuizSubmitted(false);
                            // setQuizScore([]);
                            createMessage();
                            setLoading(true);
                        }}
                    >
                        New Quiz
                    </button>
                    <span>
                        {quizResults.totalScore} ~ {quizResults.scorePercent}
                    </span>
                </div>
            )}
        </div>
    );
}

function Quizzes(props) {
    const { t, i18n } = useTranslation();
    const { user } = useContext(UserContext);
    const { chapter, course_id, chapter_id } = props
    console.log(chapter)

    const [quiz, setQuiz] = useState([]);
    const [loading, setLoading] = useState(false);
    const [quizScore, setQuizScore] = useState([]);
    const [quizSubmited, setQuizSubmitted] = useState(false);
    const [threadID, setThreadID] = useState("");

    useEffect(() => {
        getThreads()
            .then((res) => {
                // check if user has a thread for flashcards
                if (res.data[0].quizzes_thread == " ") {
                    openai.beta.threads
                        .create()
                        .then((res) => {
                            updateThreads(res.id);
                            setThreadID(res.id);
                        })
                        .catch((err) => console.log(err));
                } else {
                    setThreadID(res.data[0].quizzes_thread);
                }
            })
            .catch((err) => console.log(err));
    }, [user]);

    useEffect(() => {
        setQuiz([]);
        setQuizSubmitted(false);
        setQuizScore([]);
    }, [chapter]);

    async function getThreads() {
        const resp = await getStudentThreads(user?.id);
        if (resp.success) {
            return resp;
        } else {
            throw new Error("Could not fetch threads");
        }
    }

    async function updateThreads(thread_id) {
        const resp = await putStudentThreads(user?.id, {
            thread_id: thread_id,
            to_update: "quizzes_thread",
        });
        if (resp.success) {
            return resp;
        } else {
            console.log(resp);
            throw new Error("Could not update threads");
        }
    }

    function generateNewQuiz(array) {
        setLoading(true);
        setQuiz([]);
        setQuizScore([]);
        for (let i = 0; i < array.length; i++) {
            // quizScore.push(null);
            setQuizScore((prevScore) => [...prevScore, null]);
        }
        setQuizSubmitted(false);
        setQuiz(array);
        setLoading(false);

        // testing
        // let setter = [
        //     {
        //         question1: 'question1',
        //         correct_answer1: 'correct_answer',
        //         incorrect_answer1: 'incorrect_answer1',
        //         incorrect_answer2: 'incorrect_answer2',
        //         incorrect_answer3: 'incorrect_answer3',
        //         explanation: 'explanation'
        //     },
        //     {
        //         question1: 'question2',
        //         correct_answer1: 'correct_answer',
        //         incorrect_answer1: 'incorrect_answer1',
        //         incorrect_answer2: 'incorrect_answer2',
        //         incorrect_answer3: 'incorrect_answer3',
        //         explanation: 'explanation'
        //     },
        //     {
        //         question1: 'question3',
        //         correct_answer1: 'correct_answer',
        //         incorrect_answer1: 'incorrect_answer1',
        //         incorrect_answer2: 'incorrect_answer2',
        //         incorrect_answer3: 'incorrect_answer3',
        //         explanation: 'explanation'
        //     },
        //     {
        //         question1: 'question4',
        //         correct_answer1: 'correct_answer',
        //         incorrect_answer1: 'incorrect_answer1',
        //         incorrect_answer2: 'incorrect_answer2',
        //         incorrect_answer3: 'incorrect_answer3',
        //         explanation: 'explanation'
        //     },
        //     {
        //         question1: 'question5',
        //         correct_answer1: 'correct_answer',
        //         incorrect_answer1: 'incorrect_answer1',
        //         incorrect_answer2: 'incorrect_answer2',
        //         incorrect_answer3: 'incorrect_answer3',
        //         explanation: 'explanation'
        //     },
        // ]

        // for (let i = 0; i < setter.length; i++)
        // {
        //     setQuizScore(prevScore => [...prevScore, null])
        // }

        // setQuiz(setter)
        // setQuizSubmitted(false)
        // setLoading(false)
    }

    async function createMessage() {
        setQuiz([]);
        setLoading(true);
        const resp = await getQuizQuestions(
            course_id,
            chapter_id
        );

        if (resp.data.length >= 5) {
            //flashcards to be shown, display them and exit
            let transformedData = resp.data.map((item) => ({
                ...item,
                question1: item.question, // Replace 'question' with 'question1'
                correct_answer1: item.answer, // Replace 'answer' with 'correct_answer'
                answer: undefined, // Remove old 'answer'
                question: undefined, // Remove old 'question'
            }));
            generateNewQuiz(transformedData);
            setLoading(false);
            return;
        }

        openai.beta.threads.runs
            .list(threadID)
            .then((res1) => {
                if (res1.body.first_id == null) {
                    openai.beta.threads.messages
                        .create(threadID, {
                            role: "user",
                            content: `use the generateNewQuiz function five separate times with different questions than the last, and no similiar questions to help me study the content of ${chapter}.`,
                        })
                        .then((res) => {
                            runAssistant();
                            setLoading(true);
                        })
                        .catch((err) => console.log(err));
                } else {
                    openai.beta.threads.runs
                        .retrieve(threadID, res1.body.first_id)
                        .then((res) => {
                            if (res.status == "requires_action") {
                                submitTools(res1.body.first_id);
                                // setError(false)
                            } else if (
                                res.status == "completed" ||
                                res.status == "expired" ||
                                res.status == "failed" ||
                                res.status == "incomplete"
                            ) {
                                // setError(false)
                                openai.beta.threads.messages
                                    .create(threadID, {
                                        role: "user",
                                        content: `use the generateNewQuiz function five separate times with different questions than the last, and no similiar questions to help me study the content of ${chapter}.`,
                                    })
                                    .then((res) => {
                                        runAssistant();
                                        setLoading(true);
                                    })
                                    .catch((err) => console.log(err));
                            } else {
                                // setError(true)
                                setTimeout(() => {
                                    createMessage();
                                }, 5000);
                            }
                        })
                        .catch((err) => console.log("error"));
                }
            })
            .catch((err) => console.log("error"));
    }

    async function runAssistant() {
        setLoading(true);
        openai.beta.threads.runs
            .create(threadID, {
                assistant_id: assistant.id,
            })
            .then((res) => {
                setLoading(true);
                retrieveRun(res.id);
            });
    }

    function retrieveRun(run_id) {
        openai.beta.threads.runs.retrieve(threadID, run_id).then((res) => {
            if (res.status == "in_progress" || res.status == "queued") {
                setLoading(true);
                setTimeout(() => {
                    retrieveRun(run_id);
                    console.log("running");
                }, 6000);
            } else if (res.status == "requires_action") {
                submitTools(run_id);
                console.log("submitted");
            } else if (res.status == "completed") {
                // setError(true)
                console.log("error");
                console.log(res);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    async function submitTools(run_id) {
        setLoading(true);

        const runStatus = await openai.beta.threads.runs.retrieve(
            threadID,
            run_id
        );

        const toolCalls =
            runStatus.required_action.submit_tool_outputs.tool_calls;

        const args = [];
        toolCalls.map((tool) => args.push(JSON.parse(tool.function.arguments)));
        const toolIds = [];
        const outputs = [];

        toolCalls.map((tool) => toolIds.push(tool.id));
        toolIds.map((toolID) =>
            outputs.push({
                tool_call_id: toolID,
                output: "output",
            })
        );

        openai.beta.threads.runs
            .submitToolOutputs(threadID, run_id, {
                tool_outputs: outputs,
            })
            .then((res) => generateNewQuiz.apply(null, [args]));
    }

    async function getMessages() {
        const threadMessages = await openai.beta.threads.messages.list(
            threadID
        );
        console.log(threadMessages);
    }

    return (
        <div
            className="main-container"
            style={quiz.length <= 0 ? { alignItems: "center" } : {}}
        >
            <button
                onClick={createMessage}
                disabled={threadID == "" || loading ? true : false}
                style={threadID == "" || loading ? { background: "grey" } : {}}
                className="start-quiz-button"
            >
                {t("quizzes.startQuiz")}
            </button>
            {/* <button onClick={getMessages}>Messages</button> */}

            {quiz.length > 0 ? (
                <GeneratedQuiz
                    quiz={quiz}
                    setQuiz={setQuiz}
                    quizScore={quizScore}
                    setQuizScore={setQuizScore}
                    createMessage={createMessage}
                    quizSubmited={quizSubmited}
                    setQuizSubmitted={setQuizSubmitted}
                    setLoading={setLoading}
                    chapter_id={chapter_id}
                    course_id={course_id}
                />
            ) : !loading ? (
                <div className="intro-screen">
                    <img src={ourLogo} style={{ width: "150px" }} />
                    <span>Generate Quizzes with our new AI assistant!</span>
                    <span>Select a course to begin</span>
                </div>
            ) : (
                <div className="loading-screen">
                    <div class="loading-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Quizzes;

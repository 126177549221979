import { userConstants } from "../constant";
import { userService } from "../services";

export const registerActions = {
  registerInstructor,
};

function registerInstructor(payload) {
  return (dispatch) => {
    dispatch(request());
    return userService
      .registerCreator(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data));
          if (response.data.message === "Email verified Successfully!") {
            // window.location.reload();
          }
          console.log(response.data);
        } else {
          dispatch(failure(response));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_INSTRUCTOR_REQUEST, user };
  }
  function success(auth) {
    return { type: userConstants.REGISTER_INSTRUCTOR_SUCCESS, auth };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_INSTRUCTOR_FAILURE, error };
  }
}

import React, { useState, useEffect, Suspense, useContext } from "react";
import { icons } from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import { countryAction } from '../../containers/unauthedUser/actions/country';
import { Link } from "react-router-dom";
import Card from "../Common/CourseCard";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import Login from "../modals/Login";
import Signup from "../modals/Signup";
import ForgotPassword from "../modals/ForgotPassword";
import InstructorSignup from "../modals/InstructorSignup";
import { getDataCall } from "../services/utils";
import Loader from "../Common/Loader";
import { showError } from "../Utils/helper";
import { UserContext } from "../context/AuthContext";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import SelectCountryModal from "../modals/SelectCountryModal";

function Home({ category }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { loggedIn } = useContext(UserContext);
  const [testimonial, setTestimonial] = useState([]);
  const [banner, setBanner] = useState([]);
  const [advert, setAdvert] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [recCourses, setRecCourses] = useState([]);
  const HomeCarousel = React.lazy(() => import("../Common/HomeCarousel"));
  const userCountry = useSelector((state) => state.unauthedUserInfo.country);

  const TestimonialCarousel = React.lazy(() =>
    import("../Home/TestimonialCarousel")
  );
  const CategoryHomeComponent = React.lazy(() =>
    import("../Home/CategoryHomeComponent")
  );

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  const [openLoginModal, closeLoginModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          onClick={() => {
            closeLoginModal();
            setShow(false);
          }}
        >
          <img src={icons.icon_close} alt="close" />
        </button>
        <Login
          forgotOpen={openForgotPasswordModal}
          loginClose={closeLoginModal}
          signupOpen={openSignupModal}
        />
      </ReactModal>
    );
  });
  const [openSignupModal, closeSignupModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          onClick={() => {
            closeSignupModal();
            setShow(false);
          }}
        >
          <img src={icons.icon_close} alt="close" />
        </button>
        <Signup closeSignup={closeSignupModal} openLogin={openLoginModal} />
      </ReactModal>
    );
  });
  const [openInstructorSignupModal, closeInstructorSignupModal] = useModal(
    () => {
      setShow(true);
      return (
        <ReactModal
          isOpen
          ariaHideApp={false}
          className="react_modal"
          overlayClassName="modal_overlay"
        >
          <button
            type="button"
            className="close"
            onClick={() => {
              closeInstructorSignupModal();
              setShow(false);
            }}
          >
            <img src={icons.icon_close} alt="close" />
          </button>
          <InstructorSignup
            closeSignup={closeInstructorSignupModal}
            openLogin={openLoginModal}
          />
        </ReactModal>
      );
    }
  );
  const [openForgotPasswordModal, closeForgotPasswordModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          onClick={() => {
            closeForgotPasswordModal();
            setShow(false);
          }}
        >
          <img src={icons.icon_close} alt="close" />
        </button>
        <ForgotPassword
          closeForgot={closeForgotPasswordModal}
          openLogin={openLoginModal}
        />
      </ReactModal>
    );
  });

  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [geolocationError, setGeolocationError] = useState(null);

  const recommendedCourse = async () =>  {
    let resp;
    if (!loggedIn && userCountry === null) {
      resp = await getDataCall(`/v1/Courses?tag_id=2&lat=${latitude}&long=${longitude}`);
    } else if (userCountry !== null) {
      resp = await getDataCall(`/v1/Courses?tag_id=2&countryId=${userCountry.id}`);
    } else {
      resp = await getDataCall(
        `/v1/Courses?tag_id=2&auth=${Cookies.get("auth")}`
      );
    }
    setRecCourses(resp.data.data);
  }

  const getCountryInfo = async () => {
    const response = await getDataCall(`/v1/country?lat=${latitude}&long=${longitude}`);
    return response.data;
  }

  async function getBanner() {
    const resp = await getDataCall("/v1/banners");
    if (resp.success) {
      setBanner(resp?.data);
    } else {
      showError(t('alertMessage.wrong'));
    }
  }
  async function getTestimonial() {
    const resp = await getDataCall("/v1/testimonial");
    if (resp.success) {
      setTestimonial(resp.data);
    } else {
      showError(t('alertMessage.wrong'));
    }
  }

  async function getAdvert() {
    const resp = await getDataCall("/v1/vert");
    if (resp.success) {
      setAdvert(resp.data.filter((obj, i) =>  i <=3));
      setLoading(false);
    } else {
      showError(t('alertMessage.wrong'));
    }
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }, (error) => {
      setGeolocationError(error);
    })
  }, [])

  const [openSelectCountryModal, closeSelectCountryModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <SelectCountryModal
          closeModal={closeSelectCountryModal}
          setRecCourses={setRecCourses}
          setShow={setShow}
        />
      </ReactModal>
    );
  });


  useEffect(() => {
    if (!loggedIn && geolocationError?.code === 1 && userCountry === null) { // user denied location
      openSelectCountryModal();
    }
    if (!loggedIn && ((latitude === null || longitude === null) && userCountry === null)) return; // one of the values is missing, do not make the network call
    if(!loggedIn && userCountry === null) { // no country info, go get it and get the associated courses
      getCountryInfo().then(countryInfo => {
        if (countryInfo.id !== 112 && countryInfo.id !== 17) { // region/country is not being served at this point show modal to select region.
          openSelectCountryModal();
        } else {
          dispatch(countryAction.idUnauthedCountry({id: countryInfo.id, name: countryInfo.name}))
          localStorage.setItem('unauthedUserCountry', JSON.stringify({id: countryInfo.id, name: countryInfo.name}))
        }
      });
    }
    recommendedCourse();
  }, [latitude, longitude, geolocationError])

  useEffect(() => {
    getBanner();
    getTestimonial();
    getAdvert();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div>
        <HomeCarousel banner={banner} />
        {recCourses.length > 0 && (
          <section className="mb_100" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
            <div className="container">
              <div className="section_title mb_40">
                <div className="title_wrapper">
                  <figure className="title_icon">
                    <img src={icons.icon_student_viewing} alt="" />
                  </figure>
                  <h3>{t('common.studentsAreViewing')}</h3>
                </div>
                <Link to="/courses/all" className="view_all_link">
                  {t('common.exploreMore')}
                </Link>
              </div>
              <div className="row">
                {recCourses?.map((course, index) => (
                  <Card
                    key={index}
                    id={course.id}
                    title={course.name}
                    tutor={`${course.instructor.first_name} ${course.instructor.last_name}`}
                    price={course.is_free === 0 ? course.course_sale : t("common.free")}
                    thumbnail={course.course_image}
                    isFree={course?.is_free}
                    data={course}
                    isPurchased={course?.is_purchased}
                    translation={course?.translation}
                    instructorsList={course?.instructors}
                  />
                ))}
              </div>
            </div>
          </section>
        )}
        <CategoryHomeComponent category={category} />
        <TestimonialCarousel data={testimonial} />
        
        <section className="mb_100">
          <div className="container">
            <div className="row">
              {
                advert && advert.length > 0 && advert.map(obj=> {
                  return <div className={`col-md-${12/advert.length}`} key={obj.id}>
                    <a target="_blank" href={obj.url}>
                      <img
                        src={process.env.REACT_APP_ASSETS + obj.img_path}
                        alt="advert"
                        className="homepage-advert-img"
                      />
                    </a>
                  </div>
                })
              }
            </div>
          </div>
        </section>

        <section className="mb_100">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <a
                  href="#"
                  className="small_banner"
                  onClick={openInstructorSignupModal}
                >
                  <img src={i18n.language == 'ar' ? icons.ar_become_instructor_banner : icons.become_instructor_banner} alt="" />
                </a>
              </div>
              <div className="col-12 col-md-6">
                <a href="#" className="small_banner" onClick={openSignupModal}>
                  <img src={i18n.language == 'ar' ? icons.ar_register_banner : icons.register_banner} alt="" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Suspense>
  );
}

export default Home;

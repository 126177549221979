import React, { useState, useContext } from "react";
import Loader from "../components/Common/Loader";
import PageLayout from "../components/Layouts/PageLayout";
import { getStudentOrders } from "../components/services/student/index";
import { showError, utcToLocalDateTime, utcToLocalDateTimeLangWise } from "../components/Utils/helper";
import { UserContext } from "../components/context/AuthContext";
import InstructorView from "../components/orders/InstructorView";
import { messages } from "../constants/message";
import { useTranslation } from "react-i18next";
import getTranslationFromMany from "../services/helper";
import { useSelector } from "react-redux";

function MyOrders() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const userCountry = useSelector((state) => state.unauthedUserInfo.country);

  console.log(data)


  async function toGetStudentOrders() {
    setLoading(true);
    const resp = await getStudentOrders();
    if (resp.success) {
      setData(resp.data);
      setLoading(false);
    } else {
      showError(t('alertMessage.wrong'));
    }
  }

  React.useEffect(() => {
    toGetStudentOrders();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PageLayout>
          {user?.type === "student" ? (
            <section className="container mt-5 my-order-section" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
              <div className="row mb-4">
                <div className="col-12">
                  <h3>{t('common.myOrders')}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table profile_payment_table table-bordered">
                      <thead>
                        <tr>
                          <th>{t('common.date')}</th>
                          <th className="course_name_len">{t('common.courseName')}</th>
                          <th>{t('common.amount')}</th>
                          <th>{t('common.transactionStatus')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length === 0 ? (
                          <h2 className="p-4 text-center">{t('common.noResultFound')}</h2>
                        ) : (
                          <>
                            {data?.map((_item) => (
                              <tr key={_item?.id}>
                                <td>
                                  {utcToLocalDateTimeLangWise(_item?.order?.created_at, i18n.language == 'ar' ? 'ar-EG' : 'en-US')}
                                </td>
                                <td>{t('dynamic', { en: _item?.courses?.name, ar: getTranslationFromMany(_item?.courses?.translation, 'name', _item?.courses?.name) })}</td>
                                <td>{_item?.courses?.country_id === 112 ? t('course.price_in_kd', { price: _item?.order?.final_total}) : t('course.price_in_bd', { price: _item?.order?.final_total})}</td>
                                {_item?.order?.status === "CAPTURED" ? (
                                  <td style={{ color: "#00C707" }}>
                                    {t('common.successful')}
                                  </td>
                                ) : (
                                  <td style={{ color: "red" }}>{t('common.failed')}</td>
                                )}
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <InstructorView />
          )}
        </PageLayout>
      )}
    </>
  );
}

export default MyOrders;

import { utilsConstants } from "../constants";
import { utilsServices } from "../services";

export const countryAction = {
  getCountry,
};

function getCountry(userType = null) {
  return (dispatch) => {
    dispatch(request());
    return utilsServices
      .country()
      .then((response) => {
        let obj = [];
        if(userType === 'student')
        {
          response.data.data.filter(item => ['Kuwait','Bahrain','Oman','Saudi Arabia','Qatar','UAE'].includes(item.name)).forEach((country) => {
              obj.push({ value: country.id, label: country.name })
          });
        }
        else
        {
          response.data.data.forEach((country) => {
              obj.push({ value: country.id, label: country.name })
          });
        }
        dispatch(success(obj));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: utilsConstants.REQUEST_COUNTRY, user };
  }
  function success(country) {
    return { type: utilsConstants.RECIEVE_COUNTRY, country };
  }
  function failure(error) {
    return { type: utilsConstants.ERROR_COUNTRY, error };
  }
}

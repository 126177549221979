import { toast } from "react-toastify";
import moment, { lang, utc } from "moment";
import {
  addToCart,
  addToWishList,
  deleteCourseCart,
  getCartCount,
  getCartDetails,
  emptyUserCart
} from "../services/courses/index";
import { Trans, useTranslation } from "react-i18next";

export const showError = (message, time = 2000, position="bottom-right") => {
  toast.error(message, {
    hideProgressBar: true,
    position: position,
    autoClose: time,
  });
};

export const scrollToSelector = (selector) => {
  const section = document.querySelector('#' + selector);
  section.scrollIntoView({behavior: 'smooth', block: 'start'});
};

export const showSuccess = (message) => {
  toast.success(message, {
    hideProgressBar: true,
    position: "bottom-right",
    autoClose: 2000,
  });
};

export const Spinner = () => {
  return (
    <div
      className="spinner"
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: "5px",
      }}
    />
  );
};

export const addCourseToCart = async (
  payload,
  helperProps,
  loaderText,
  data,
  customMsg,
  openEmptyCartModal,
) => {
  // const differingRegions = checkDifferingCourseRegions(helperProps.cartData, data, openEmptyCartModal);
  // if (differingRegions) return;
  //loop here if data.countryId is not the same as cart.courses.country id then dont add, differing regions
  if (!helperProps.loggedIn) {
    addToCartLocalStorage(helperProps, data, customMsg);
  } else {
    helperProps.setLoadingCart(loaderText);
    const resp = await addToCart(payload);
    if (resp.success) {
      showSuccess(customMsg?.addToCartSuccess);
      const _count = await getCartCount();
      const _resp = await getCartDetails();
      helperProps.setCartCount(_count.data);
      helperProps.setCartData(_resp.data);
      helperProps.setLoadingCart(null);
    } else {
      helperProps.setLoadingCart(null);
      showSuccess(customMsg?.courseExist);
    }
  }
};

function addToCartLocalStorage(helperProps, data, customMsg) {
  let payload = JSON.parse(localStorage.getItem("cartCourse")) || [];
  let count = 0;
  if (payload.length === 0) {
    payload = [data, ...payload];
    localStorage.setItem("cartCourse", JSON.stringify(payload));
    helperProps.setCartCount(helperProps.cartCount + 1);
    helperProps.setCartData(JSON.parse(localStorage.getItem("cartCourse")));
    showSuccess(customMsg?.addToCartSuccess);
    return;
  }
  payload.map((_item) => {
    if (_item.id === data.id) {
      showSuccess(customMsg.courseExist);
      count++;
    }
  });
  if (count === 0) {
    let newPayload = [data, ...payload];
    localStorage.setItem("cartCourse", JSON.stringify(newPayload));
    helperProps.setCartCount(helperProps.cartCount + 1);
    helperProps.setCartData(JSON.parse(localStorage.getItem("cartCourse")));
    showSuccess(customMsg?.addToCartSuccess);
  }
}

// const checkDifferingCourseRegions = (cartItems, course, openEmptyCartModal) => {
//   if(cartItems === null) return; //cart is empty
//   for (let item of cartItems) {
//     if (item.courses.country_id !== course.country_id) {
//       openEmptyCartModal();
//       return true
//     }
//   } return false;
//   return false;
// }

export const removeCourseFromCart = async (data, helperProps, loaderText, history, customMsg) => {
  if (!helperProps.loggedIn) {
    let payload = JSON.parse(localStorage.getItem("cartCourse"));
    let newPayload = payload.filter((_item) => _item.id !== data.id);
    localStorage.setItem("cartCourse", JSON.stringify(newPayload));
    helperProps.setCartCount(helperProps.cartCount - 1);
    helperProps.setCartData(JSON.parse(localStorage.getItem("cartCourse")));
    showSuccess(customMsg?.removeFromCart);
  } else {
    helperProps.setLoadingCart(loaderText)
    const resp = await deleteCourseCart(data.course_id)
    if (resp.success) {
      showSuccess(customMsg?.removeFromCart);
      if (history.location.pathname === "/checkout") {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }

      const _count = await getCartCount();
      const _resp = await getCartDetails();
      helperProps.setCartCount(_count.data);
      helperProps.setCartData(_resp.data);
      helperProps.setLoadingCart(null);
    } else {
      helperProps.setLoadingCart(null);
      showSuccess(customMsg?.removeFromCart);
    }
  }
}

export const emptyCart = async (loggedIn, setCartCount, setCartData, setLoadingCart) => {
  if(!loggedIn) {
    console.log('hit')
    localStorage.setItem("cartCourse", null);
    setCartCount(0);
    setCartData([]);
    // showSuccess("Cart successfully emptied.")
  } else {
    const resp = await emptyUserCart();
    if(resp.success) {
      const _count = await getCartCount();
      const _resp = await getCartDetails();
      setCartCount(_count.data);
      setCartData(_resp.data);
      showSuccess("Cart successfully emptied.")
    } else {
      showError("Something went wrong.")
    }
  }
}

export const addToWishListData = async (payload, helperProps) => {
  if (!helperProps.loggedIn) {
    showSuccess(helperProps.t('alertMessage.loginWishlist'));
  } else {
    helperProps.setLoadingCart("wishlist-loading");
    const resp = await addToWishList(payload);
    if (resp.success) {
      showSuccess(helperProps.t('alertMessage.addedWishlist'));
      helperProps.setLoadingCart(null);
    } else {
      showSuccess(helperProps.t('alertMessage.wishListExists'));
      helperProps.setLoadingCart(null);
    }
  }
};

let format = "MMM yyyy";
export const utcToLocalDateTime = (utcTime) => {
  try {
    const utc_timeZ = moment.utc(utcTime).format("DD MMM YYYY")
    // const localTime = moment.utc(utc_timeZ).toDate();
    // const formatString = moment(localTime).format(format);
    // return formatString == "Invalid date" ? "-" : formatString;
    return utc_timeZ;
  } catch (e) {
    return "-";
  }
};

export const utcToLocalDateTimeLangWise = (utcTime, lang= 'en-US') => 
{
  try {
    const date = new Date(Date(utcTime));
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString(lang, options);
  } catch (e) {
    return "-";
  }
}

export const addCourseToCartBulk = (token, _cartCourse) => {
  let _payload;
  _cartCourse.map((_item) => {
    // console.log(_item);
    // _payload.push()
  });
};

export const cities = [
  "Al Asimah",
  "Hawalli",
  "Mishref",
  "Qadesiya",
  "Safat",
  "Salmiya",
  "al-Ahmadi",
  "al-Farwaniyah",
  "al-Jahra",
  "al-Kuwayt",
  "Bayan",
  "Massilah",
  "Mushrif",
  "Salwa",
  "Sha''ab",
  "Subbah-as-Salim",
  "al-Funaytis",
  "al-Funaytis-al-Garbiyah",
  "al-Jabiriyah",
  "al-Karim",
  "ar-Rumaythiyah",
  "as-Salimiyah",
  "A'qaylah",
  "Abu Hulayfah",
  "Dahar",
  "Desert Area",
  "Hadiyah",
  "Jabbar-al-'Ali",
  "Shu'aybah",
  "al-Fintas",
  "al-Fuhayhil",
  "al-Mahbulah",
  "al-Manqaf",
  "al-Wafrah",
  "ar-Riqqah",
  "as-Sabahiyah",
  "az-Zawr",
  "'Umayriyah",
  "Abraq Khitan",
  "Ardiyah",
  "Fardaws",
  "Jalib ash-Shuyuh",
  "Khitan-al-Janubiyah",
  "Qartaba",
  "Ray",
  "Riqay",
  "Sabhan",
  "Sarbah-an-Nasr",
  "Warmawk",
  "al-Andalus",
  "ar-Rabbiyah",
  "Amgarah",
  "Nasim",
  "Tayma'",
  "Uyawn",
  "Waha",
  "al-Qusayr",
  "as-Sulaybiyah",
  "'Abullah-as-Salam",
  "Ardhiyah",
  "Banayd-al-Qar",
  "Health District",
  "Kayfan",
  "Khalidiyah",
  "Mansuriyah",
  "Nuzha",
  "Qarnadah",
  "Shamiyah",
  "ad-Da'iyah",
  "ad-Dasma",
  "ad-Dawhah",
  "al-'Udayliyah",
  "al-Fayha",
  "al-Qadisiyah",
  "ar-Rawdah",
  "as-Sulaybihat",
  "ash-Shuwaykh Industrial",
  "ash-Shuwaykh Reservoir",
  "Abu Fataira",
  "West Abdullah Al Mubarak",
  "Jaber Al Ahmad",
  "Zahra",
  "Hateen",
  "Shuhada",
  "Al-Siddeeq",
  "Salam",
  "Saad Al Abdullah",
];

import { unauthedUserConstants } from "../constants";

const initialState = {
    loading: true,
    error: false,
    country: null,
  },
  unauthedCountryReducer = (state = initialState, action) => {
    switch (action.type) {
      case unauthedUserConstants.RECIEVE_COUNTRY_INFO:
        return Object.assign({}, state, {
          loading: false,
          error: false,
          country: action.country,
        });
      case unauthedUserConstants.ERROR_COUNTRY_INFO:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

export default unauthedCountryReducer;

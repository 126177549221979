import Cookies from 'js-cookie'
import Pusher from 'pusher-js'

Pusher.logToConsole = true

const pusher = new Pusher('9d64b7533aa0c120993e', {
	cluster: 'ap2',
	broadcaster: 'pusher',
	encrypted: true, //set it to true for https schema
	forceTLS: true, //set it to true for https schema
	disableStats: true,
	authEndpoint: `${process.env.REACT_APP_ASSETS}/broadcasting/auth`,
	auth: {
		headers: {
			Authorization: `Bearer ${Cookies.get("EAPISID")}`,
		},
	},
})

pusher.connection.bind('error', function (err) {
	console.log('>>> detected limit error', err)
})

export const subscribeChannel = (id) => {
	const channel = pusher.subscribe(`private-App.DosUser.${id}`)

	channel.bind('pusher:subscription_succeeded', function (members) {
		console.log('successfully subscribed!')
	})
	return channel
}

// pusher.allChannels().forEach(channel => console.log(channel.name));
import React, { useEffect, useState } from "react";
import { icons } from "../../constants/icons";
import { Link } from "react-router-dom";
import PageLayout from "../Layouts/PageLayout";
import { getStudentList } from "../services/discussionForum";
import { showError, Spinner } from "../Utils/helper";
import Loader from "../Common/Loader";
import { ar_messages, messages } from "../../constants/message";
import { useTranslation } from "react-i18next";
function StudentListComponent() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getStudents() {
    setLoading(true);
    const resp = await getStudentList();
    if (resp.success) {
      setData(resp?.data?.data);
    } else {
      showError(t('alertMessage.wrong'));
    }
    setLoading(false);
  }
  useEffect(() => {
    getStudents();
  }, []);
  return (
    <PageLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section class="blue_section">
            <div class="container">
              <h1 class="blue_section_title text-center mtb_50">{t('common.myStudents')}</h1>
            </div>
          </section>
          {data?.length === 0 ? (
            <h2 className="p-4 text-center">{i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}</h2>
          ) : (
            <div className="container mtb_50">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <div className="row">
                    {data?.map((item) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={item?.id}
                      >
                        <div className="instructor_box">
                          {/* <img
                      src={icons.instructor}
                      style={{
                        borderRadius: "50%",
                        width: "150px",
                        height: "150px",
                      }}
                      className="mb-3"
                      alt="instructor image"
                    /> */}
                          <div class="instructor_profile">
                            {/* <img src="img/testimony-user.png" alt="" /> */}
                            <div>
                              {`${item?.first_name[0]} ${item?.last_name[0]}`}
                            </div>
                          </div>

                          <div className="instructor_box_name">{`${item?.first_name} ${item?.last_name}`}</div>

                          <Link
                            to={{
                              pathname: `/chat/${item?.id}`,
                              state: { data: item },
                            }}
                            className="instructor_btn"
                            style={{ color: "#fff", cursor: "pointer" }}
                          >
                            Send message
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </PageLayout>
  );
}

export default StudentListComponent;

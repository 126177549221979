import axios from "axios";
import Cookies from "js-cookie";

export const profileService = {
  updateProfile,
};

function handleUserResponse(response) {
  return response;
}

function updateProfile(payload) {
  const requestOptions = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("EAPISID"),
    },
  });

  return requestOptions
    .post("/auth/userProfileUpdate", JSON.stringify(payload))
    .then(handleUserResponse)
    .catch((error) => {
      // console.log(error);
    });
}

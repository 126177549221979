import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../context/AuthContext";
import Select from "react-select";
import { countryAction } from "../../containers/Utils/actions/country";
import { addAddressApi } from "../services/address";
import { showError, showSuccess } from "../Utils/helper";
import { cities } from "../Utils/helper";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const customStyles = {
    padding: "5px 0px",
    borderRadius: "11px",
    borderCcolor: "red",
};

export default function AddAddress() {
    const { t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();    
    const { loggedIn, loadingUser } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const country = useSelector((state) => state.country);
    const [countryName, setCountryName] = useState({
        value: 112,
        label: t('countries.Kuwait', "Kuwait"),
    });
    const [cityName, setCityName] = useState(null)
    const [details, setDetails] = useState({
        category: "",
        pin_code: "",
        country_id: "",
        state: "",
        block: "",
        street: "",
        apartment: "",
        directions: "",
        city: "",
        address: "",
    });
    let city = [];
    cities.map((_city) => {
        let data = { label: _city, value: _city };
        city.push(data);
    });
    
    useEffect(() => {
        setLoading(true);
        dispatch(countryAction.getCountry());
    }, []);

    async function submitAddress(e) {
        e.preventDefault();
        setSpinner(true);
        if (!loggedIn) {
            showError(t('alertMessage.signUpToPurchase'));
            return;
        }

        let payloads = {
            ...details,
            country_id: countryName.value,
            city: countryName?.value === 112 ? cityName?.value : details?.city, 
        }

        const resp = await addAddressApi(payloads)
        if (resp) {
            showSuccess(t('alertMessage.success'));
            let previous_route = localStorage.getItem("previous_route");
            if(previous_route)
            {
                localStorage.removeItem("previous_route");
                history.push({
                    pathname: previous_route,
                });                
            }
            else
            {
                history.push({
                    pathname: "/addresses",
                    search: "all",
                });
            }
        } else {
            showError("Error");
        }
        setSpinner(false);
    }

    function changeHandler(e) {
        const { name, value } = e.target;

        setDetails({
        ...details,
        [name]: value,
        });
    }


    return (
        <section className="pt_50 pb_50" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>            
            <form onSubmit={submitAddress}>
                <div className="add-address-container container">
                    <h2 className="mb_30">{t('common.enterAddressDetails')}</h2>
                    <div className="row">  
                        <div className="col-md-4 col-lg-4">
                            <div className="cart_wrapper">
                                <div className="form-group">
                                    <label className="required-field">
                                    {t('common.category')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="category"
                                        value={details.category}
                                        placeholder={t('common.homeCompanyPent')}
                                        onChange={changeHandler}
                                        required
                                    />
                                </div>                                
                                {countryName.value === 112 ? (
                                    <>                                       
                                        <div className="form-group">
                                            <label className="required-field">{t('common.block')}</label>
                                            <input
                                                type="text"
                                                name="block"
                                                className="form-control"
                                                placeholder={t('common.block')}
                                                required
                                                value={details.block}
                                                onChange={changeHandler}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="required-field">{t('common.street')}</label>
                                            <input
                                                type="text"
                                                name="street"
                                                value={details.street}
                                                onChange={changeHandler}
                                                className="form-control"
                                                placeholder={t('common.street')}
                                                required
                                            />
                                        </div>
                                    </>
                                ):(
                                    <>
                                        <div className="form-group">
                                            <label className="required-field">{t('common.address')}</label>
                                            <input
                                                type="text"
                                                name="address"
                                                className="form-control"
                                                placeholder={t('common.roomBuildingStreet')}
                                                maxLength="120"
                                                required
                                                value={details.address}
                                                onChange={changeHandler}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cart_wrapper">
                                {countryName.value === 112 ? (
                                    <>    
                                        <div className="form-group">
                                            <label className="required-field">
                                            {t('common.houseBuilding')}
                                            </label>
                                            <input
                                                type="text"
                                                name="house"
                                                value={details.house}
                                                onChange={changeHandler}
                                                className="form-control r"
                                                placeholder={t('common.houseBuilding')}
                                                required
                                            />
                                        </div>  
                                        <div className="form-group">
                                            <label>
                                            {t('common.apartment')}
                                            </label>
                                            <input
                                                type="text"
                                                name="apartment"
                                                value={details.apartment}
                                                onChange={changeHandler}
                                                className="form-control"
                                                placeholder= {t('common.apartment')}
                                            />
                                        </div> 
                                    </>
                                ) : (
                                    <>
                                        <div className="form-group">
                                            <label className="required-field">
                                            {t('common.state')}
                                            </label>
                                            <input
                                                type="text"
                                                name="state"
                                                value={details.state}
                                                onChange={changeHandler}
                                                className="form-control r"
                                                placeholder={t('common.state')}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="required-field">{t('common.city')}</label>
                                            <input
                                                type="text"
                                                name="city"
                                                value={details.city}
                                                onChange={changeHandler}
                                                className="form-control"
                                                placeholder={t('common.city')}
                                                required
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cart_wrapper">
                                {countryName.value === 112 ? (
                                    <>
                                        <div className="form-group">
                                            <label className="required-field">
                                            {t('common.chooseArea')}
                                            </label>
                                            <Select
                                                placeholder={t('common.select')}
                                                options={city}
                                                className="react_select"
                                                value={cityName}
                                                onChange={(selected) => {
                                                    setCityName(selected);
                                                }}
                                            />
                                        </div> 
                                        <div className="form-group">
                                            <label className="required-field">{t('common.country')}</label>
                                            <Select
                                                name="form-field-name"
                                                className="react_select"
                                                value={countryName}
                                                onChange={(selected) => {
                                                setCountryName(selected);
                                                }}
                                                options={country.country.map(item => {
                                                    return { label: t('countries.'+item.label, item.label), value: item.value }
                                                  })}
                                                styles={customStyles}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="">{t('common.jaddahDirections')}</label>
                                            <textarea
                                                name="directions"
                                                value={details.directions}
                                                onChange={changeHandler}
                                                rows="5"
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="form-group">
                                            <label className="required-field">
                                            {t('common.pinCode')}
                                            </label>
                                            <input
                                                type="text"
                                                name="pin_code"
                                                value={details.pin_code}
                                                onChange={changeHandler}
                                                className="form-control r"
                                                placeholder={t('common.pinCode')}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="required-field">{t('common.country')}</label>
                                            <Select
                                                name="form-field-name"
                                                className="react_select"
                                                value={countryName}
                                                onChange={(selected) => {
                                                setCountryName(selected);
                                                }}
                                                options={country.country.map(item => {
                                                    return { label: t('countries.'+item.label, item.label), value: item.value }
                                                  })}
                                                styles={customStyles}
                                                required
                                            />
                                        </div>  
                                    </>
                                )}
                                <button
                                    className="btn_primary big_btn full_btn mt-3"
                                    type="submit"
                                >
                                    {spinner && (
                                        <span className="mr-2 spinner-border spinner-border-sm" />
                                    )}
                                    {t('common.submit')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    )
}

import React, { useState} from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ResetPasswordModal = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");




  // const handleSubmit = () => {
  //   if (newPassword !== confirmPassword) {
  //     setError("Password is mismatch");
  //     return;
  //   }
  //   // Call the onSubmit prop with the passwords
  //   onSubmit(oldPassword, newPassword);
  //   onClose(); // Close the modal after submission
  // };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="react_modal"
      overlayClassName="modal_overlay"
    >
      <div className="modal_content">
        <h2>Reset Password</h2>
        <div className="form_group">
          <label>Old Password</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder={"Enter Your Old Password"}
          />
        </div>
        <div className="form_group">
          <label>New Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder={"Enter Your New Password"}
          />
        </div>
        <div className="form_group">
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t("Enter Your Confirm Password")}
          />
        </div>
        {error && <p className="error_text">{error}</p>}
        <div className="modal_actions">
          <button className="btn_secondary" onClick={onClose}>
            Cancel
          </button>
          <button className="btn_primary" >
            Submit
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ResetPasswordModal;

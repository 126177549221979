import "../../assets/styles/chatbot.css"

import { useState, useEffect, useContext, useRef } from 'react'
import { getStudentThreads, putStudentThreads } from '../services/student'
import { UserContext } from "../context/AuthContext";

import { OpenAI } from 'openai'

import { IoMdSend } from "react-icons/io";
import logo from '../../assets/image/logo_resized.png'

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
})

const assistant = await openai.beta.assistants.retrieve(process.env.REACT_APP_CHATBOT_ID)

function Chatbot(props)
{
    const { setOpenChatbot } = props
    const { user } = useContext(UserContext);

    let chatboxRef = useRef()

    const [conversation, setConversation] = useState([
        {
            person: 'assistant',
            text: 'How can I help you today?'
        }
    ])
    const [studentInput, setStudentInput] = useState('')
    const [threadID, setThreadID] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        openai.beta.threads.create()
        .then(res => {
          setThreadID(res.id)
        })
        .catch(err => console.log(err))
    }, [])

    // useEffect(() => {
    //     getThreads()
    //     .then((res) => {
    //       // check if user has a thread
    //       if (res.data[0].chatbot_thread == ' '){
    //         openai.beta.threads.create()
    //           .then(res => {
    //             updateThreads(res.id)
    //             setThreadID(res.id)
    //           })
    //           .catch(err => console.log(err))
    //       }else{
    //         setThreadID(res.data[0].chatbot_thread)
    //       }
    //     })
    //     .catch((err) => console.log(err))
    // }, [user])

    // async function getThreads()
    // {
    //     const resp = await getStudentThreads(user?.id);
    //     if (resp.success)
    //     {
    //         return resp
    //     } else 
    //     {
    //         throw new Error('Could not fetch threads');
    //     }
    // }

    // async function updateThreads(thread_id)
    // {
    //     const resp = await putStudentThreads(user?.id, {
    //       thread_id: thread_id,
    //       to_update: 'chatbot_thread'
    //     });
    //     if (resp.success) {
    //       return resp
    //     } else {
    //     //   console.log(resp)
    //       throw new Error('Could not update threads');
    //     }
    // }

    function handleInputChange(e)
    {
        setStudentInput(e.target.value)
    }

    function submitStudentInput()
    {
        if (studentInput != '')
        {
            conversation.push({
                person: 'student',
                text: studentInput
            })
            createMessage()
            setStudentInput('')
            setTimeout(() => chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight, 100)
            setLoading(true)
            conversation.push({
                person: 'assistant',
                text: '...'
            })
            setTimeout(() => chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight, 100)
        }
    }

    async function createMessage(){
        openai.beta.threads.runs.list(threadID)
        .then(res1 => {
            if (res1.body.first_id == null){
            openai.beta.threads.messages.create(threadID, {
                role: "user",
                content: `I have a question: ${studentInput}. Please use the document called chatbot-questions to find the answer`,
            })
                .then((res) => {
                    runAssistant()
                    setLoading(true)
                })
                .catch(err => console.log(err))
            } else {
                openai.beta.threads.runs.retrieve(threadID, res1.body.first_id)
                    .then(res => {
                        if (res.status == 'completed' || res.status == 'expired' || res.status == 'failed' || res.status == 'incomplete') {
                        openai.beta.threads.messages.create(threadID, {
                            role: "user",
                            content: `I have a question: ${studentInput}. Please use the document called chatbot-questions.pdf to find the answer`,
                        })
                        .then((res) => {
                            runAssistant()
                            setLoading(true)
                        })
                        .catch(err => console.log(err))
                        } else {
                            // console.log(res)
                        }
                    })
                    .catch(err => console.log('error'))
            }
    
        })
        .catch(err => console.log('error'))
    }

    async function runAssistant(){
        setLoading(true)
        openai.beta.threads.runs.create(threadID, {
            assistant_id: assistant.id
        })
        .then((res) => {
        setLoading(true)
        retrieveRun(res.id)
        })
    }
        
    function retrieveRun(run_id){
        openai.beta.threads.runs.retrieve(threadID, run_id)
        .then(res => {
            if (res.status == 'in_progress' || res.status == 'queued'){
                setLoading(true)
                setTimeout(() => {
                    retrieveRun(run_id)
                    console.log('running')
                }, 4000)
            } else if (res.status == 'completed'){
                getMessages()
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    async function getMessages()
    {
        openai.beta.threads.messages.list(threadID)
            .then(res => {
                let response = res.data[0].content[0].text.value
                let role = res.data[0].role
                const regex = /【[^】]*】/g;

                if (response.includes('【'))
                {
                    response = response.replace(regex, '')
                }

                if (response.includes("the document"))
                {
                    response = response.replace("the document", "Dawraty")
                } else if (response.includes("The document"))
                {
                    response = response.replace("The document", "Dawraty")
                }
                conversation.pop()
                setConversation(prevConvo => [...prevConvo, {
                    person: role,
                    text: response
                }])
                setTimeout(() => chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight, 100)
            })
            .catch(err => console.log(err))
    }
    
    return (
        <div className="chatbot">

            <div ref={chatboxRef} className="chatbot-dialog">
                {conversation.map((query, index) => 
                    <div className="conversation-component" style={query.person == 'student' ? {background: '#0b4475'} : {border: '1px solid grey'}}>
                        {query.person == 'assistant' ? <img src={logo} className="conversation-ai-img"/> : null} 
                        <span className="conversation-text" style={query.person == 'student' ? {color: 'white'} : {}}>{query.text}</span>
                    </div>
                )}
            </div>

            <div className="chatbot-input">
                {loading || threadID == '' ?
                    <span className="please-wait">Please wait...</span>
                :
                    <div className="chatbot-bottom-bar">
                        <input 
                            value={studentInput} 
                            onKeyDown={(e) => {if (e.key == 'Enter'){submitStudentInput()}}} 
                            onChange={(e) => handleInputChange(e)} 
                            placeholder="Ask a question!" 
                            className="chatbot-input-text" 
                            type="text"
                            disabled={loading || threadID == ''}
                        />
                        <IoMdSend className="chatbot-input-submit" onClick={submitStudentInput}/>
                    </div>
                }

            </div>
        </div>
    )
}

export default Chatbot
import React, { useEffect, useState, useRef, useContext } from "react";
import { withRouter, useLocation } from "react-router";
import {
  getAllComments,
  postComments,
} from "../components/services/discussionForum";
import {
  showError,
  utcToLocalDateTime,
  Spinner,
  showSuccess
} from "../components/Utils/helper";
import Navbar from "../components/navigation/Navbar";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import { icons } from "../constants/icons";
import QAForm from '../components/modals/QAForm';
import { ToastContainer } from "react-toastify";
import { UserContext } from "../components/context/AuthContext";
import { useTranslation } from "react-i18next";

function Askdoubt(props) {
  const { t } = useTranslation();
  const messageBox = useRef(null);
  const { user } = useContext(UserContext);
  const location = useLocation();
  const param_user = location.state.data;
  const arrow = (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="17.5px"
      height="17.5px"
      viewBox="0 0 535.5 535.5"
      style={{ "enable-background": "new 0 0 535.5 535.5" }}
      xmlSpace="preserve"
    >
      <g>
        <g id="send">
          <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75 		" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
  const [data, setData] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const _id = props.match.params.id;

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  async function getComments() {
    setLoading(true);
    const resp = await getAllComments(_id);
    if (resp.success) {
      setData(resp.data.reverse());
    } else {
      showError(t('alertMessage.wrong'));
    }
    setLoading(false);
  }

  useEffect(() => {
    getComments();
  }, []);

  async function sendMessage(e) {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    if (message.length > 0) {
      let payload = { 
        comment: message,
      };
      const resp = await postComments(_id, payload);
      if (resp.success) {
        // console.log("message sent");
      } else {
        showError(t('alertMessage.wrong'));
      }
      getComments();
    }
    setLoading(false);
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const [openQAModal, closeQAModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          onClick={() => {
            closeQAModal();
            setShow(false);
          }}
        >
          <img src={icons.icon_close} alt="close" />
        </button>
          <QAForm modalClose={closeQAModal} showSuccess={showSuccess} />
      </ReactModal>
    );
  });

  function handleFileChange(e) {
    const {name, files, value} = e.target;
    if(e.target.files[0].size/1024/1024 < 30)
    {
      setLoading(true);
      let fileExt = value.split(".");
      let fileExtReverse = fileExt.reverse();
      getBase64(files[0]).then(
        data => {
          sendAttachment(data,fileExtReverse[0]);
        }
      );
      setLoading(false);
    }
    else
    {
      e.target.value = null;
      showError(t('alertMessage.fileNotMoreThan'));
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async function sendAttachment(data, fileExt) {
    if(data !== "" && data !== null && fileExt !== "" && fileExt !== null) {
      let payload = { 
        msg_type: "attachment",
        attachment: data,
        file_ext: fileExt,
      };
      const resp = await postComments(_id, payload);
      if (resp.success) {
        console.log("message sent");
      } 
      else {
        if(resp.status === 422) {
          showError(t('alertMessage.fileNotMoreThan'));
        }
        else
        {
          showError(t('alertMessage.wrong'));
        }
      }
      getComments();   
    }
  }
  
  return (
    <>
      <Navbar />
      <section class="message_section">
        <div class="container">
          <div class="messages">
            <div class="msg_header">
              <div class="msg_profile">
                <div>
                  {param_user?.first_name[0]}
                  {param_user?.last_name[0]}
                </div>
              </div>
              <div class="msg_profile_details">
                <div class="msg_name">
                  {param_user?.first_name} {param_user?.last_name}
                </div>
              </div>
            { user?.type === "instructor" && (<button className="btn btn-primary mt-3 QAButton" onClick={openQAModal}>Highlight Question</button>)}
            </div>

            <div class="msg_win">
              <>
                {loading ? (
                  <div className="d-flex justify-content-between align-items-center empty-chat">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {data?.length === 0 ? (
                      <div className=" d-flex justify-content-center align-items-center empty-chat">
                        <h2 className="text-center">
                          {t('common.startConversation')}
                        </h2>
                      </div>
                    ) : (
                      <>
                        {data &&
                          data?.map((_comment) => {
                            return (
                              <div key={_comment?.id}>
                                {_comment?.user_id == _id ? (
                                  <div class="msg_row msg_receiver">
                                    {
                                      _comment.attachment !== '' && _comment.attachment !== null ? (
                                        <div class="msg_file">
                                          <a href={process.env.REACT_APP_ASSETS + _comment?.attachment?.attachment_path} className="download_button" download target="_blank">
                                            <img src={icons.icon_download_black} alt="download" />
                                          </a>
                                          {_comment?.attachment?.attachment_file }
                                        </div> 
                                      ) : (
                                        <div class="msg_text">
                                          {_comment?.comment }
                                        </div>
                                      )
                                    }
                                  
                                    <div class="msg_date">
                                      {" "}
                                      {utcToLocalDateTime(_comment?.created_at)}
                                    </div>
                                  </div>
                                ) : (
                                  <div class="msg_row msg_sender">
                                    {
                                      _comment.attachment !== '' && _comment.attachment !== null ? (
                                        <div class="msg_file">
                                          <a href={process.env.REACT_APP_ASSETS + _comment?.attachment?.attachment_path} className="download_button" download target="_blank">
                                            <img src={icons.icon_download} alt="download" />
                                          </a>
                                          {_comment?.attachment?.attachment_file }
                                        </div> 
                                      ) : (
                                        <div class="msg_text">
                                          {_comment?.comment }
                                        </div>
                                      )
                                    }
                                    <div class="msg_date">
                                      {utcToLocalDateTime(_comment?.created_at)}
                                    </div>
                                  </div>
                                )}
                                <AlwaysScrollToBottom />
                              </div>
                            );
                          })}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
            <div class="msg_footer">
              <div class="msg_input">
                <form onSubmit={sendMessage} className="grey_input w-100 mt-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('common.typeMessage')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className="attachment_button">
                    <input 
                      type="file"
                      id="attachment"
                      onChange={handleFileChange}
                    />
                    <label className="form-label" for="attachment">
                      <img src={icons.icon_attachment}  alt="" />
                    </label>
                  </div>
                  <span
                    className="ml-2 svg_icon cursor_pointer"
                    onClick={sendMessage}
                  >
                    {arrow}
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
          <ToastContainer />
      </section>
    </>
  );
}

export default withRouter(Askdoubt);

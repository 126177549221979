import React, { useEffect, useState } from "react";
import { icons } from "../../constants/icons";
import { Link } from "react-router-dom";
import { getAddressesList } from "../services/address";
import { showError, showSuccess, Spinner } from "../Utils/helper";
import AddressCard from "./addressCard";
import { messages, ar_messages } from '../../constants/message'
import { useTranslation } from "react-i18next";

function AddressList() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getAddresses() {
    setLoading(true);
    const resp = await getAddressesList();
    if (resp.success) {
      setData(resp?.data);
    } else {
      showError(t('alertMessage.wrong'));
    }
    setLoading(false);
  }


  useEffect(() => {
    getAddresses();
  }, []);

  return (
    <div className="container mtb_50" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="row">
          {data?.length === 0 ? (
            <h2 className="p-4 text-center">{i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}</h2>
          ) : (
            <>
              {data?.map((item) => (
                <AddressCard item={item} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default AddressList;

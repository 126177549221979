import { getAuthDataCall, postAuthDataCall, deleteAuthDataCall } from "../utils";

export const getAddressesList = async () => {
  const resp = await getAuthDataCall(`/auth/addresses`);
  return resp;
};

export const getAddressApi = async (id) => {
  const resp = await getAuthDataCall(`/auth/addresses/${id}`);
  return resp;
};

export const addAddressApi = async (payload) => {
  const resp = await postAuthDataCall(`/auth/addresses/add`, payload);
  return resp;
};

export const updateAddressApi = async (id, payload) => {
  const resp = await postAuthDataCall(`/auth/addresses/${id}/edit`, payload);
  return resp;
};

export const deleteAddressApi = async (id) => {
  const resp = await deleteAuthDataCall(`/auth/addresses/${id}/delete`);
  return resp;
};


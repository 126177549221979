import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getAuthDataCall2, getDataCall } from "../services/utils";
import { showError } from "../Utils/helper";
import { logoutUser } from "../navigation/navbar/helper";
import { subscribeChannel } from '../../pusherinit'
import { setUnreadNotificationToRead } from '../services/notifications';
import {t, useTranslation} from "react-i18next";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  let cookie = Cookies.get("EAPISID");
  let loggedIn = cookie === undefined || cookie === null || cookie === "" ? false : cookie;
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(true);
  const [category, setCategory] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState(null);
  const { t } = useTranslation();

  async function userGet() {
    if (loggedIn) {
        setLoadingUser(true);
        const response = await getAuthDataCall2("/me");
        if(response.success !== true && response.status === 403) {                     
          Cookies.remove("EAPISID");
          Cookies.remove("auth");       
          if(window.location.pathname !== "/under-verification"){
            window.location.href = "/under-verification";
          }
        }
        else{
          setToken(response.success)
        }
        setUser(response.data);
        setLoadingUser(false);
    }
  }

  async function getCategory() {
    const resp = await getDataCall("/v1/category");
    if (resp.success) {
      setCategory(resp.data);
    } else {
      showError(t('alertMessage.wrong'));
    }
  }

  async function getNotifications() {
    if (loggedIn) {
      const resp = await getAuthDataCall2("/auth/notifications/recent_unread");
      if (resp.success) {
        setNotifications(resp.data);
      } else {
        showError(t('alertMessage.wrong'));
      }
    }
  }

  const showAllNotitfication = async () => {
    await setUnreadNotificationToRead();
    setNotificationCount(0);
  }

  async function getNotificationCount() {
    if (loggedIn) {
      const resp = await getAuthDataCall2("/auth/notifications/unread_count");
      if (resp.success) {
        setNotificationCount(resp.data);
      } else {
        showError(t('alertMessage.wrong'));
      }
    }
  }
  
  useEffect(() => {
    userGet();
    getCategory();
    getNotificationCount();
    getNotifications();
  }, []);
  
  useEffect(() => {
    if (loggedIn && user !== null) {
      const channel = subscribeChannel(user.id);
      console.log('User Subcribed !!', user);
      channel.bind('SendFrontendNotification', function (data) {
        // console.log('Received data:', data)
        getNotificationCount();
        getNotifications();
      });
    }
  }, [user])

  useEffect(() => {
    if(!token){
      logoutUser()
    }
  }, [token])
  
  return (
    <UserContext.Provider
      value={{
        loggedIn: loggedIn,
        user: user,
        loadingUser: loadingUser,
        category: category,
        notifications: notifications,
        notificationCount: notificationCount,
        resetNotification: showAllNotitfication,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React, { useState, useEffect } from "react";
import { icons } from "../../constants/icons";
import { getStudentCourse } from "../services/student";
import { showError, Spinner } from "../Utils/helper";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ar_messages, messages } from "../../constants/message";
import { useTranslation } from "react-i18next";
import getTranslationFromMany from "../../services/helper";

export default function StudentAllCourses() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(false);
  const history = useHistory();

  async function toGetStudentCourse() {
    setLoading(true);
    const resp = await getStudentCourse();
    if (resp.success) {
      setData(resp.data);
      setSearch(resp.data);
      setLoading(false);
    } else {
      showError("Somthing went error");
      setLoading(false);
    }
  }

  // const handleSearchChange = () => {
  //   console.log(_searchParam);
  //   let filterArr = search?.filter((_search) => {
  //     _search?.courses?.name?.toLowerCase().includes(_searchParam);
  //   });
  //   console.log(filterArr);
  //   // setData(filterArr);
  // };
  useEffect(() => {
    let filterArr = search?.filter((_search) => {
      _search?.courses?.name?.toLowerCase().includes(searchParam);
    });
    // console.log(filterArr);
    // setData(filterArr);
  }, [searchParam]);
  useEffect(() => {
    toGetStudentCourse();
  }, []);

  const Slider = ({ course }) => {
    let count = 0;
    let completedLectures = 0;
    let completed = 0;
    course?.courses?.chapter?.map((_item) => {
      count += _item?.lessons_count;
      completedLectures += _item?.student_update_lesson?.length;
    });
    completed = (completedLectures / count) * 100;
    return (
      <div className="cc_bar">
        <div className="continue_track">
          <div
            className="continue_bar"
            style={{ width: parseInt(completed) + "%" }}
          />
        </div>
        <span>{t('common.completed', { percentage: parseInt(completed)})}</span>
      </div>
    );
  };
  return (
    <>
      <React.Fragment>
        <section className="pt_100 pb_50" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
          <div className="container">
            <div className="course_search mb_30">
              <div className="search">
                <form action="">
                  <div className="search_input" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
                    <div className="search_magnify">
                      <img src={icons.icon_search} alt="" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('common.search')}
                      onChange={(e) => {
                        setSearchParam(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </div>
              {/* <div className="course_filter">
                  <select name="" id="" className="form-control">
                    <option value="">Title: A - Z</option>
                    <option value="">Title: Z - A</option>
                    <option value="">Recently Accessed</option>
                    <option value="">Recently Enrolled</option>
                  </select>
                </div> */}
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <div className="row">
                {data?.length === 0 ? (
                  <h2 className="p-4 text-center">{i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}</h2>
                ) : (
                  <>
                    {data?.map((_item) => (
                      <Link
                        className="col-12 col-md-3"
                        key={_item?.id}
                        to={{
                          pathname: "/course",
                          state: _item?.course_id,
                        }}
                      >
                        <div className="course_box student_course mtb_15">
                          <div className="course_thumbnail">
                            <img
                              src={
                                process.env.REACT_APP_ASSETS +
                                _item?.courses?.course_image
                              }
                              alt=""
                            />
                          </div>
                          <div className="student_course_inner">
                            <h2 className="course_box_title">
                              {t('dynamic', { en: _item?.courses?.name, ar: getTranslationFromMany(_item?.courses?.translation, 'name', _item?.courses?.name) })}
                            </h2>
                            {<Slider course={_item} />}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </>
                )}
              </div>
            )}{" "}
          </div>
        </section>
      </React.Fragment>
    </>
  );
}

import { userConstants } from "../constant";

const initialState = {
  loading: false,
  error: false,
  auth: [],
};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true,
        error: false,
        auth: [],
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loading: false,
        error: false,
        auth: action.auth,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        error: true,
        loading: false,
        auth: action.error,
      };
    case userConstants.LOGIN_FAILURE_DEVICE:
      return {
        error: true,
        loading: false,
        auth: action.error,
        message: 'device_exceeded',
      }
    default:
      return state;
  }
}

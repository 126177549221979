import { userConstants } from "../constant";
import { userService } from "../services";

export const userActions = {
  getUser,
};

function getUser() {
  return (dispatch) => {
    dispatch(request());
    return userService
      .user()
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data));
        } else {
          dispatch(failure(response));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: userConstants.REQUEST_USER, user };
  }
  function success(user) {
    return { type: userConstants.RECEIVE_USER, user };
  }
  function failure(error) {
    return { type: userConstants.ERROR_USER, error };
  }
}

import React, { useContext } from 'react'
import { useDispatch } from 'react-redux';
import { countryAction } from '../../containers/unauthedUser/actions/country';
import { getDataCall } from '../services/utils';
import { emptyCart } from '../Utils/helper';
import { CartContext } from "../context/CartContext"
import kuwait from '../../assets/image/country-flags/kuwait.svg'
import bahrain from '../../assets/image/country-flags/bahrain.svg'

/*
* TODO: maybe clean this code up a little bit? Manually passing country id, country name.
*/
function SelectCountryModal({ closeModal, setRecCourses, setShow }) {
    const dispatch = useDispatch();
    const { setCartCount, setCartData } = useContext(CartContext);


    const handleSelectCountry = async (id, name) => {
        dispatch(countryAction.idUnauthedCountry({id: id, name: name}))
        localStorage.setItem('unauthedUserCountry', JSON.stringify({id: id, name: name}))

        const resp = await getDataCall(`v1/Courses?tag_id=2&countryId=${id}`)
        setRecCourses(resp.data.data);
        // clear cart
        emptyCart(false, setCartCount, setCartData)
        closeModal();
        setShow(false);
    }

    return (
    <div>
        <>
            <div className="lrf_modal">
            <h2>Select Region</h2>
            <div className="lrf_subtext">
                <p>Kindly select one of the following regions below to proceed.</p>
                <p>يرجى اختيار إحدى المناطق التالية للمتابعة</p>
            </div>
            <div className="select-country-modal">
                <button className="flag-button" onClick={() => handleSelectCountry(112, "Kuwait")}>
                    <img style={{height: '64px', width: '106px', margin: '10px'}} src={kuwait} alt='kuwait flag'/>
                    <p style={{margin: '6px'}}>Kuwait</p>
                </button>
                <button className="flag-button" onClick={() => handleSelectCountry(17, "Bahrain")}>
                    <img style={{height: '64px', width: '106px', margin: '10px'}} src={bahrain} alt='bahrain flag'/>
                    <p style={{margin: '6px'}}>Bahrain</p>
                </button>
            </div>
            </div>
        </>
    </div>
    );
}

export default SelectCountryModal;

import React, { useEffect } from "react";
import { icons } from "../../constants/icons";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getInstructorDashboardData } from "../services/instructors";
import { showError, utcToLocalDateTime } from "../Utils/helper";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";

function InstructorHome() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function toGetDashboardData() {
    setLoading(true);
    const resp = await getInstructorDashboardData();
    if (resp.success) {
      setData(resp.data);
    } else {
      showError(t('alertMessage.wrong'));
    }
    setLoading(false);
  }
  useEffect(() => {
    toGetDashboardData();
  }, []);
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <section className="section-m">
          {/* <div className="instructor-bg" />
          <div className="instructor-bg2">
            <div className="container">
              <div className="inst-wrapper">
                <div className="inst-imgbg">
                  <img
                    src={process.env.REACT_APP_ASSETS + data?.img_path}
                    alt=""
                    className="instructor-img"
                  />
                </div>
                <div className="instructor-name">{`${data?.first_name} ${data?.last_name}`}</div>
              </div>
            </div>
          </div> */}

          <div className="container mt_40">
            <div className="dashboard">
              <div className="dashboard-bgimg">
                <img
                  src={icons.instructor_dashboard}
                  alt=""
                  className="dashboard-img"
                />
              </div>
              <div className="dashboard-text ml_15">{t('common.myDashboard')}</div>
            </div>
            <div className="card1-main mt_30">
              <Link to="/my-courses" className="card1">
                <div className="card1-text">{t('common.myCourses')}</div>
                <div>
                  <img
                    src={icons.instructor_play}
                    alt=""
                    className="card1-icon"
                  />
                </div>
                <div className="card1-value">{data?.courses_count}</div>
              </Link>
              <div className="card1">
                <div className="card1-text">{t('common.myStudents')}</div>
                <div>
                  <img
                    src={icons.instructor_bundle}
                    alt=""
                    className="card1-icon"
                  />
                </div>
                <div className="card1-value">{data?.total_students_count}</div>
              </div>
              <div className="card1">
                <div className="card1-text">Questions</div>
                <div>
                  <img
                    src={icons.icon_questions}
                    alt=""
                    className="card1-icon"
                  />
                </div>
                <div className="card1-value">
                  {data?.questions_count}
                </div>
              </div>
            </div>
            {data?.recent_orders?.length > 0 && (
              <div className="table-main mt-5">
                <div className="table-headText">Recent Order</div>
                <table className="table table-borderless table-responsive pr_30">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      {/* <th scope="col">Amount</th> */}
                      <th scope="col">Course</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.recent_orders?.map((_order) => (
                      <tr>
                        <td>{utcToLocalDateTime(_order?.created_at)}</td>
                        {/* <td>KD {_order?.amount}</td> */}
                        <td>{_order?.course_details?.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="btn-bg">
                  <Link to="/my-orders" className="view-btn">
                    View all
                  </Link>
                </div>
              </div>
            )}
            {data?.recent_courses?.length > 0 && (
              <>
                <div className="dashboard">
                  <div className="dashboard-bgimg">
                    <img
                      src={icons.instructor_recent_course}
                      alt=""
                      className="dashboard-img"
                    />
                  </div>
                  <div className="dashboard-text ml_15">My Recent Courses</div>
                </div>
                <div className="recent-detail">
                  {data?.recent_courses?.map((_course) => (
                    <div className="recent-main">
                      <div>
                        <img
                          src={
                            process.env.REACT_APP_ASSETS + _course?.course_image
                          }
                          alt=""
                          className="recent-img"
                        />
                      </div>
                      <div className="recent-text ml_40">{_course?.name}</div>
                    </div>
                  ))}
                  <div className="btn-bg">
                    <Link to="/my-courses" className="view-btn">
                      View all
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default InstructorHome;

{
  /* <div className="card2-main">
						<div className="card2">
							<div className="card2-detail">
								<div className="card2-text">Current Month Course Purchased</div>
								<div>
									<div className="card2-value">KD 0</div>
									<div className="card2-perc">
										20%{' '}
										<span>
											<img src={icons.instructor_uparrow} alt="" className="card2-upArrow" />
										</span>
									</div>
								</div>
							</div>
							<div className="card2-detail">
								<div className="card2-link">See all</div>
								<div>
									<img src={icons.instructor_gps} alt="" className="card2-icon" />
								</div>
							</div>
						</div>
						<div className="card2">
							<div className="card2-detail">
								<div className="card2-text">Life-Time Course Purchase</div>
								<div className="card2-value">KD 0</div>
							</div>
							<div className="card2-detail">
								<div className="card2-link">See all</div>
								<div>
									<img src={icons.instructor_calculator} alt="" className="card2-icon" />
								</div>
							</div>
						</div>
					</div> */
}

import { userConstants } from "../constant";
import { userService } from "../services";

export const newPasswordActions = {
  newPassword,
};

function newPassword(email, password, cpassword) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService
      .newPassword(email, password, cpassword)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data));
        } else {
          dispatch(failure(response.data));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: userConstants.REQUEST_NEW_PASSWORD, user };
  }
  function success(password) {
    return { type: userConstants.RECEIVE_NEW_PASSWORD, password };
  }
  function failure(error) {
    return { type: userConstants.ERROR_NEW_PASSWORD, error };
  }
}

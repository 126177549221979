import React,{ useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import './languageSelector.css';

function LanguageSelector() {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    useEffect(() => {
      if(i18n.language !== undefined && selectedLanguage !== i18n.language)
      {
        setSelectedLanguage(i18n.language);
      }
    },[i18n.language])

    return (
        <div className="lng-container">
          <div
            className="lng-labels"
            htmlFor={`lng-new`} 
          >
            <span className={selectedLanguage == 'en' ? "lng-button active" : "lng-button"} onClick={() => {i18n.changeLanguage("en")}}>
              <p className="lng-text">En</p>
            </span>
            <span className={selectedLanguage == 'ar' ? "lng-button right active" : "lng-button right"} onClick={() => {i18n.changeLanguage("ar")}}>
              <p className="lng-text">Ar</p>
            </span>
          </div>
        </div>
    )
}

export default LanguageSelector;
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LiveSession = ({ name, url, date }) => {
    const { t, i18n } = useTranslation();
    const [isEnabled, setIsEnabled] = useState(false);
    const [countdown, setCountdown] = useState("");

    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, "0");
        const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
        const year = d.getFullYear();
        const hours = d.getHours().toString().padStart(2, "0");
        const minutes = d.getMinutes().toString().padStart(2, "0");
        return `${day}/${month}/${year} at ${hours}:${minutes}`;
    };

    const formattedDate = formatDate(date);

    useEffect(() => {
        let timer = "";
        const sessionDate = new Date(date);
        sessionDate.setMinutes(sessionDate.getMinutes() - 15); // Makes the button enabled 15 mins before the session

        // Function to update countdown
        const updateCountdown = () => {
            const now = new Date();
            const timeLeft = sessionDate - now;

            if (timeLeft <= 0) {
                setIsEnabled(true);
                setCountdown("00:00:00");
                clearInterval(timer); // Stop the timer if the session time is reached or passed
                return;
            }

            // Calculate hours, minutes, and seconds remaining
            const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
            const seconds = Math.floor((timeLeft / 1000) % 60);

            // Format the countdown string
            const formatted = [
                hours.toString().padStart(2, "0"),
                minutes.toString().padStart(2, "0"),
                seconds.toString().padStart(2, "0"),
            ].join(":");

            setCountdown(formatted);
        };

        // Update countdown every second
        updateCountdown(); // Initial update
        timer = setInterval(updateCountdown, 1000);

        return () => clearInterval(timer);
    }, [date]);

    const handleRedirect = () => {
        // Redirect to the URL in a new tab
        window.open(url, "_blank");
    };

    return (
        <div style={styles.container}>
            <h4>{name}</h4>
            <p>
                Please click below to redirect to the live session. You will be
                able to join the session 15 mins before the start time.
            </p>
            <p>Session Date: {formattedDate}</p>
            <p>Time until session: {countdown}</p>
            <button
                onClick={handleRedirect}
                disabled={!isEnabled}
                style={{
                    backgroundColor: isEnabled ? "#0b4474" : "gray",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: isEnabled ? "pointer" : "not-allowed",
                }}
            >
                Live Session
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100vh",
        textAlign: "center", // Center the text
    },
};

export default LiveSession;

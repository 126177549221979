import { getAuthDataCall, postAuthDataCall } from "../utils";

export const getNotificationsList = async () => {
  const resp = await getAuthDataCall(`/auth/notifications`);
  return resp;
};

export const setUnreadNotificationToRead = async () => {
  const resp = await postAuthDataCall(`/auth/notifications/mark_all_unread_to_read?_method=PUT`);
  return resp;
};

export const getNotificationApi = async (id) => {
  const resp = await getAuthDataCall(`/auth/notifications/${id}`);
  return resp;
};
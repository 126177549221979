import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getCartCount, getCartDetails } from "../services/courses/index";

export const CartContext = createContext();

// This context provider is passed to any component requiring the context
export const CartProvider = ({ children }) => {
  let courseFromLS = JSON.parse(localStorage.getItem("cartCourse"));
  let cookie = Cookies.get("EAPISID");
  let loggedIn =
    cookie === undefined || cookie === null || cookie === "" ? false : cookie;
  const [cartCount, setCartCount] = useState(
    !loggedIn ? (courseFromLS === null ? 0 : courseFromLS.length) : 0
  );
  const [cartData, setCartData] = useState(!loggedIn ? courseFromLS : []);

  async function getCart() {
    const resp = await getCartDetails();
    if (resp.success) {
      setCartData(resp.data);
    }
  }
  async function cartCountData() {
    const resp = await getCartCount();
    if (resp.success) {
      setCartCount(resp.data);
      const _resp = await getCartDetails();
      setCartData(_resp.data);
    }
  }
  useEffect(() => {
    if (loggedIn) {
      getCart();
      cartCountData();
    }
  }, []);
  return (
    <CartContext.Provider
      value={{
        cartCount: cartCount,
        setCartCount: setCartCount,
        cartData: cartData,
        setCartData: setCartData,
        loggedIn: loggedIn,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

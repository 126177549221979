export const userConstants = {
  REGISTER_STUDENT_FAILURE: "USERS_REGISTER_FAILURE",
  REGISTER_STUDENT_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_STUDENT_SUCCESS: "USERS_REGISTER_SUCCESS",

  REGISTER_INSTRUCTOR_REQUEST: "REGISTER_INSTRUCTOR_REQUEST",
  REGISTER_INSTRUCTOR_SUCCESS: "REGISTER_INSTRUCTOR_SUCCESS",
  REGISTER_INSTRUCTOR_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGIN_FAILURE_DEVICE: "USERS_EXCEED_DEVICE_LIMIT",

  REQUEST_USER: "REQUEST_USER",
  RECEIVE_USER: "RECEIVE_USER",
  ERROR_USER: "ERROR_USER",

  REQUEST_FORGOT_PASSWORD_MAIL: "REQUEST_FORGOT_PASSWORD_MAIL",
  RECEIVE_FORGOT_PASSWORD_MAIL: "RECEIVE_FORGOT_PASSWORD_MAIL",
  ERROR_FORGOT_PASSWORD_MAIL: "ERROR_FORGOT_PASSWORD_MAIL",

  REQUEST_FORGOT_VERIFY_OTP: "REQUEST_FORGOT_VERIFY_OTP",
  RECEIVE_FORGOT_VERIFY_OTP: "RECEIVE_FORGOT_VERIFY_OTP",
  ERROR_FORGOT_VERIFY_OTP: "ERROR_FORGOT_VERIFY_OTP",

  REQUEST_EMAIL_OTP: "REQUEST_EMAIL_OTP",
  RECEIVE_EMAIL_OTP: "RECEIVE_EMAIL_OTP",
  ERROR_EMAIL_OTP: "ERROR_EMAIL_OTP",

  REQUEST_NEW_PASSWORD: "REQUEST_NEW_PASSWORD",
  RECEIVE_NEW_PASSWORD: "RECEIVE_NEW_PASSWORD",
  ERROR_NEW_PASSWORD: "ERROR_NEW_PASSWORD",

  RESEND_OTP: "RESEND_OTP",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "RESEND_OTP_FAILURE",

  CLEAR_RESEND_OTP_STATE: "CLEAR_RESEND_OTP_STATE",

  LOGOUT: "USERS_LOGOUT",
};

import React, { useContext } from "react";
import { deleteCourseCart, deleteChapter } from "../services/courses";
import { showError, showSuccess } from "../Utils/helper";
import { UserContext } from "../context/AuthContext";
import { CartContext } from "../context/CartContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function UserCartData({ cartArr }) {
  const { t } = useTranslation();
  const { loggedIn } = useContext(UserContext);
  const { cartCount, setCartCount, setCartData, cartData } = useContext(CartContext);
  const userCountry = useSelector((state) => state.unauthedUserInfo.country);

  function multipleChapter(_cart) {
    let filterArrayChapter = [];
    _cart?.courses?.chapter?.map((item) => {
      _cart?.chapters?.map((_item) => {
        if (item?.id === _item) {
          filterArrayChapter.push(item);
        }
      });
    });
    return (
      <>
        {filterArrayChapter?.map((_chap) => {
          return (
            <div className="ro_row">
              <div className="ro_inner">
                <div className="ro_name">{_chap?.name}</div>
                <div className="ro_price">
                  {!loggedIn ? userCountry.id === 112 ?
                      t('course.price_in_kd', { price:  _chap?.price})
                      :
                      t('course.price_in_bd', { price:  _chap?.price})
                    :
                      cartData.country_id === 112 ?
                      t('course.price_in_kd', { price:  _chap?.price})
                      :
                      t('course.price_in_bd', { price:  _chap?.price})
                  }
                </div>
              </div>
              <a
                href="#"
                className="link"
                onClick={async () => {
                  if (!loggedIn) {
                    showError(t('alertMessage.loginToDelete'));
                    return;
                  }
                  const resp = await deleteChapter(_chap?.course_id, _chap?.id);
                  if (resp.success) {
                    showSuccess(t('alertMessage.chapterDeleted'));
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  } else {
                    showError(t("alertMessage.wrong"));
                  }
                }}
              >
                {t('common.remove')}
              </a>
            </div>
          );
        })}
      </>
    );
  }
  async function removeCourseFromCart(_id) {
    if (!loggedIn) {
      const getCourses = JSON.parse(localStorage.getItem("cartCourse"));
      const filterArr = getCourses.filter((item) => item.id !== _id);
      localStorage.setItem("cartCourse", JSON.stringify(filterArr));
      setCartCount(cartCount - 1);
      setCartData(filterArr);
      showSuccess(t("alertMessage.courseDeleted"));
      return;
    }

    const resp = await deleteCourseCart(_id);
    if (resp.success) {
      showSuccess(t("alertMessage.courseDeleted"));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      showError(t("alertMessage.wrong"));
    }
  }
  return (
    <div className="ro_body">
      {loggedIn ? (
        <>
          {cartArr?.map((_cart) => {
            return _cart?.chapters === null || _cart?.chapters?.length === 0 ? (
              <div className="ro_row">
                <div className="ro_inner">
                  <div className="ro_name">{_cart?.courses?.name}</div>
                  {_cart?.courses?.is_free === 1 ? (
                    <div className="ro_price">{t('common.free')}</div>
                  ) : _cart?.courses?.course_sale?.on_sale ? (
                    <div className="ro_price">
                      {_cart.courses.country_id === 112 ? t('course.price_in_kd', { price:  _cart?.courses?.course_sale?.new_price}) : t('course.price_in_bd', { price:  _cart?.courses?.course_sale?.new_price})}
                    </div>
                  ) : (
                    <div className="ro_price">
                      {_cart.courses.country_id === 112 ? t('course.price_in_kd', { price:  _cart?.courses?.course_sale?.old_price}) : t('course.price_in_bd', { price:  _cart?.courses?.course_sale?.old_price})}
                    </div>
                  )}
                </div>
                <a
                  href="#"
                  className="link"
                  onClick={() => removeCourseFromCart(_cart?.course_id)}
                >
                  {t('common.remove')}
                </a>
              </div>
            ) : (
              multipleChapter(_cart)
            );
          })}
        </>
      ) : (
        <>
          {
            <>
              {cartArr?.map((_cart) => {
                return (
                  <div className="ro_row">
                    <div className="ro_inner">
                      <div className="ro_name">{_cart?.name}</div>
                      {_cart?.courses?.is_free === 1 ? (
                        <div className="ro_price">{t('common.free')}</div>
                      ) : _cart?.course_sale?.on_sale ? (
                        <div className="ro_price">
                          {_cart?.course_sale?.new_price}
                        </div>
                      ) : (
                        <div className="ro_price">
                          {_cart?.course_sale?.old_price}
                        </div>
                      )}
                    </div>
                    <a
                      href="#"
                      className="link"
                      onClick={() => removeCourseFromCart(_cart?.id)}
                    >
                      {t('common.remove')}
                    </a>
                  </div>
                );
              })}
            </>
          }
        </>
      )}
    </div>
  );
}

export default UserCartData;

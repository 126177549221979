import React, { useState, useEffect } from 'react';
import { getCoursesList } from '../services/courses'
import { addQuestionAnswerApi } from '../services/question-answer'
import { showError, showSuccess } from "../Utils/helper";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

export default function QAForm(props) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [courseName, setCourseName] = useState(null);
    const [courses, setCourses] = useState(null);
    const [QAInputs, setQAInputs] = useState({
        'question' : '',
        'answer': '',
        'course_id': ''
    });

    useEffect(() => {
        getCourses();
    },[])


    async function getCourses() {
        const resp = await getCoursesList();
        if (resp.success) {
            let obj = [];
            resp.data.forEach((item) =>
                obj.push({ 
                    value: item.id, 
                    label: item.name, 
                })
            );
            setCourses(obj);
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    function handleQAChange(e) {
        const { name, value } = e.target;
        setQAInputs((inputs) => ({ ...inputs, [name]: value }));
    }

    async function submitQustionAnswer(e){
        e.preventDefault();
        setLoading(true);
        let payloads = {
            ...QAInputs,
            course_id: courseName.value,
        }

        const resp = await addQuestionAnswerApi(payloads)
        if (resp.success) {
            props.showSuccess(t('alertMessage.success'));
            props.modalClose();
        } else {
            showError("Error");
        }
        setLoading(false);
    }

    return (
        <div className="lrf_modal">
            <h2>Question Answer</h2>
            <div class="lrf_subtext">
              Enter Your Question and Answer for particular courses
            </div>
            <form onSubmit={submitQustionAnswer}>
                <div className="form-group">
                    <label for="course_id">Select Course</label>                    
                    <Select
                        options={courses}
                        className="react_select"
                        value={courseName}
                        onChange={(selected) => {
                            setCourseName(selected);
                        }}
                        required
                    />
                </div>
                <div className="form-group">
                    <label for="">Question</label>
                    <div className="form_input">
                        <input
                        type="question"
                        value={QAInputs.question}
                        className="form-control"
                        placeholder="Your Question"
                        onChange={handleQAChange}
                        name="question"
                        required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label for="">Answer</label>
                    <div className="form_input">
                        <textarea
                            className="form-control"
                            rows="4"
                            name="answer"
                            placeholder="Answer"
                            onChange={handleQAChange}
                            required
                        >
                        </textarea>
                    </div>
                </div>
                <button className="btn_primary full_btn big_btn" type="submit">
                {loading && (
                    <span className="mr-2 spinner-border spinner-border-sm" />
                )}
                    {t('common.submit')}
                </button>
          </form>
        </div>
      );
}
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../constants/icons";
import { reportFlashcard, reportQuizQuestion, reportTutorResponse } from "../services/ai";
import { showSuccess, showError } from "../Utils/helper";

/**
 *
 * @param { questionInfo: {
 *              course_id: questionInfo.course_id,
 *              chapter_id: questionInfo.chapter_id,
 *              question: questionInfo.question,
 *              answer: questionInfo.answer,
 *              user_id: questionInfo.user_id,
 *              question_id: questionInfo.question_id,
 *          },
 *          closeModal,
 *          setShow,
 *          type: 'flashcard' | 'ai_quiz' | 'ai_tutor'}
 * @returns
 */

const ReportIssueModal = ({ questionInfo, closeModal, setShow, type }) => {
    // console.log(questionInfo);
    const { t } = useTranslation();

    const [text, setText] = useState("");
    const maxCharacters = 500;

    const handleChange = (event) => {
        if (event.target.value.length <= maxCharacters) {
            setText(event.target.value);
        }
    };

    const remainingCharacters = maxCharacters - text.length;

    const handleSubmit = async () => {
        if (type === "flashcard") {
            const resp = await reportFlashcard({
                course_id: questionInfo.course_id,
                chapter_id: questionInfo.chapter_id,
                question: questionInfo.question,
                answer: questionInfo.answer,
                user_id: questionInfo.user_id,
                question_id: questionInfo.question_id,
                reasons: text,
            })
                .then((res) => {
                    showSuccess(t("alertMessage.success"));
                })
                .catch((err) => showError(t("alertMessage.wrong")));
        } else if (type === "ai_quiz") {
            //type = ai_quiz
            const resp = await reportQuizQuestion({
                course_id: questionInfo.course_id,
                chapter_id: questionInfo.chapter_id,
                question: questionInfo.question,
                answer: questionInfo.answer,
                user_id: questionInfo.user_id,
                question_id: questionInfo.question_id,
                incorrect_answer1: questionInfo.incorrect_answer1,
                incorrect_answer2: questionInfo.incorrect_answer2,
                incorrect_answer3: questionInfo.incorrect_answer3,
                explanation: questionInfo.explanation,
                reasons: text,
            })
                .then((res) => {
                    showSuccess(t("alertMessage.success"));
                })
                .catch((err) => showError(t("alertMessage.wrong")));
        } else if (type === 'ai_tutor') {
            const resp = await reportTutorResponse({
                course_id: questionInfo.course_id,
                chapter_id: questionInfo.chapter_id,
                user_id: questionInfo.user_id,
                response: questionInfo.response,
                reasons: text
            })
            .then((res) => {
                showSuccess(t("alertMessage.success"));
            })
            .catch((err) => showError(t("alertMessage.wrong")));
        }
    };

    return (
        <>
            <button
                type="button"
                className="close"
                onClick={() => {
                    closeModal();
                    setShow(false);
                }}
            >
                <img src={icons.icon_close} alt="close" />
            </button>
            <div style={{ padding: "30px" }}>
                <>
                    <div className="lrf_modal">
                        <h2>Report an issue</h2>
                        <div className="lrf_subtext">
                            <p>Please describe the issue you are facing</p>
                            <p>يرجى وصف المشكلة التي تواجهها</p>
                        </div>
                        <div className="select-country-modal"></div>
                    </div>
                </>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <textarea
                        style={{
                            width: "100%",
                            height: "200px",
                            fontSize: "16px",
                            padding: "6px",
                        }}
                        value={text}
                        onChange={handleChange}
                        placeholder={`Type your text here (Max ${maxCharacters} characters)`}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            fontSize: "14px",
                        }}
                    >
                        {remainingCharacters}
                    </div>
                </div>
                <button
                    className="btn_primary big_btn full_btn mt-3"
                    onClick={handleSubmit}
                >
                    {t("common.submit")}
                </button>
            </div>
        </>
    );
};

export default ReportIssueModal;

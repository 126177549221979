import { getAuthDataCall, postAuthDataCall } from "../utils";

export const getResult = async (id) => {
  const resp = await getAuthDataCall(`auth/studentQuiz?lesson_quiz=${id}`);
  return resp;
};

export const checkQuestion = async (payload) => {
  const resp = await postAuthDataCall(`/auth/studentQuiz`, payload);
  return resp;
};

import icon_facebook from "../assets/image/icons/icon_facebook.svg";
import icon_pinterest from "../assets/image/icons/icon_pinterest.svg";
import icon_twitter from "../assets/image/icons/icon_twitter.svg";
import icon_instagram from "../assets/image/icons/icon_instagram.svg";
import icon_whatsapp from "../assets/image/icons/img_whatsapp.png";
import icon_email from "../assets/image/icons/icon_mail.svg";
import dawraty_logo from "../assets/image/dawraty-logo.png";
import icon_category from "../assets/image/icons/icon_category.svg";
// import icon_magnify from "../assets/image/icons/icon_magnify.svg";
import audio_1 from "../assets/image/icons/audio_1.png";
import icon_mail from "../assets/image/icons/img_mail.png";
import icon_phone from "../assets/image/icons/img_phone.png";
import icon_heart from "../assets/image/icons/img_heart.png";
import icon_cart_white from "../assets/image/icons/icon_cart_white.svg";
import icon_experience from "../assets/image/icons/icon_experience.svg";
import icon_student_viewing from "../assets/image/icons/icon_student_viewing.svg";
import icon_eye from "../assets/image/icons/icon_eye.svg";
import icon_close from "../assets/image/icons/img_close.png";
import icon_google from "../assets/image/icons/icon_google.svg";
import icon_circle_play from "../assets/image/icons/icon_circle_play.svg";
import icon_access from "../assets/image/icons/img_access.png";
import icon_mobile from "../assets/image/icons/img_mobile.png";
import icon_certificate from "../assets/image/icons/icon_certificate.svg";
import icon_clock from "../assets/image/icons/icon_clock.svg";
import icon_eye_orange from "../assets/image/icons/icon_eye_orange.svg";
import icon_students from "../assets/image/icons/icon_students.svg";
import icon_video from "../assets/image/icons/img_video.png";
import icon_exercises from "../assets/image/icons/img_exercises.png";
import icon_heart_white from "../assets/image/icons/icon_heart_white.svg";
import icon_close_black from "../assets/image/icons/icon_close_black.svg";
import icon_notification from "../assets/image/icons/img_notification.png";
import icon_heart_icon from "../assets/image/icons/icon_heart_icon.svg";
import icon_order_history from "../assets/image/icons/img_order_history.png";
import icon_logout from "../assets/image/icons/icon_logout.svg";
import icon_user from "../assets/image/icons/img_user.png";
import icon_heart_blue_filled from "../assets/image/icons/icon_heart_blue_filled.svg";
import icon_cart_blue from "../assets/image/icons/icon_cart_blue.svg";
import icon_calculator from "../assets/image/icons/calculator.png";
import icon_search from "../assets/image/icons/img_search.png";
import icon_location from "../assets/image/icons/img_location.png";
import icon_cart from "../assets/image/icons/img_cart.png";
import icon_audio from "../assets/image/icons/volume.png";
import icon_pdf from "../assets/image/icons/img_documents.png";

import become_instructor_banner from "../assets/image/become-instructor-banner.png";
import register_banner from "../assets/image/register-banner.png";
import ar_become_instructor_banner from "../assets/image/ar/become-instructor-banner.png";
import ar_register_banner from "../assets/image/ar/register-banner.png";
import mid_laptop from "../assets/image/mid-laptop.png";
import course_thumbnail from "../assets/image/course-thumbnail-1.png";
import home_carousel_image from "../assets/image/home-banner-1.jpg";
import testimony_user from "../assets/image/testimony-user.png";
import instructor from "../assets/image/instructor.png";
import play_outline from "../assets/image/icons/icon_play_outline.svg";
import instructor_dashboard from "../assets/image/instructor-icons/dashboard.png";
import instructor_play from "../assets/image/instructor-icons/img_instructor_play.png";
import instructor_bundle from "../assets/image/instructor-icons/img_instructor_bundle.png";
import icon_questions from "../assets/image/instructor-icons/img_questions.png";
import instructor_group from "../assets/image/instructor-icons/group.png";
import instructor_uparrow from "../assets/image/instructor-icons/upArrow.png";
import instructor_gps from "../assets/image/instructor-icons/gps.png";
import instructor_calculator from "../assets/image/instructor-icons/calculator.png";
import instructor_recent_course from "../assets/image/instructor-icons/recent-course.png";
import cart_1 from "../assets/image/cart_1.png";
import notification_1 from "../assets/image/notification_1.png";
import bundle_course from "../assets/image/bundle_course.jpg";
import default_avatar from "../assets/image/default_avatar.jpg";
import icon_address from "../assets/image/icons/img_address.png";
import attachment from "../assets/image/icons/attachment.svg";
import download from "../assets/image/icons/icon_download.svg";
import download_black from "../assets/image/icons/icon_download_black.svg";


export const icons = {
  icon_pinterest: icon_pinterest,
  icon_twitter: icon_twitter,
  icon_facebook: icon_facebook,
  icon_instagram: icon_instagram,
  icon_whatsapp: icon_whatsapp,
  icon_email: icon_email,
  dawraty_logo: dawraty_logo,
  icon_category: icon_category,
  // icon_magnify: icon_magnify,
  // icon_cart: icon_cart,
  icon_mail: icon_mail,
  icon_phone: icon_phone,
  become_instructor_banner: become_instructor_banner,
  register_banner: register_banner,
  ar_become_instructor_banner: ar_become_instructor_banner,
  ar_register_banner: ar_register_banner,
  mid_laptop: mid_laptop,
  course_thumbnail: course_thumbnail,
  icon_heart: icon_heart,
  icon_cart_white: icon_cart_white,
  icon_student_viewing: icon_student_viewing,
  icon_experience: icon_experience,
  home_carousel_image: home_carousel_image,
  testimony_user: testimony_user,
  icon_eye: icon_eye,
  icon_close: icon_close,
  icon_close_black: icon_close_black,
  icon_google: icon_google,
  icon_circle_play: icon_circle_play,
  icon_access: icon_access,
  icon_mobile: icon_mobile,
  icon_certificate: icon_certificate,
  icon_clock: icon_clock,
  instructor: instructor,
  icon_eye_orange: icon_eye_orange,
  play_outline: play_outline,
  icon_students: icon_students,
  icon_video: icon_video,
  icon_exercises: icon_exercises,
  icon_heart_white: icon_heart_white,
  instructor_dashboard: instructor_dashboard,
  instructor_play: instructor_play,
  instructor_bundle: instructor_bundle,
  instructor_group: instructor_group,
  instructor_uparrow: instructor_uparrow,
  instructor_gps: instructor_gps,
  instructor_calculator: instructor_calculator,
  instructor_recent_course: instructor_recent_course,
  icon_notification: icon_notification,
  cart_1: cart_1,
  notification_1: notification_1,
  icon_heart_icon: icon_heart_icon,
  icon_logout: icon_logout,
  icon_user: icon_user,
  icon_heart_blue_filled: icon_heart_blue_filled,
  bundle_course: bundle_course,
  icon_cart_blue: icon_cart_blue,
  default_avatar: default_avatar,
  icon_calculator: icon_calculator,
  icon_audio: icon_audio,
  icon_pdf: icon_pdf,
  icon_search: icon_search,
  icon_cart: icon_cart,
  icon_location: icon_location,
  icon_order_history: icon_order_history,
  icon_address: icon_address,
  icon_questions: icon_questions,
  icon_audio_new: audio_1,
  icon_attachment: attachment,
  icon_download: download,
  icon_download_black: download_black,
};

import React from 'react';

export default function MainHeader(props) {
    const { title = ''} = props;
    return (        
        <section class="blue_section">
            <div class="container">
                <h1 class="blue_section_title mtb_50 text-center">
                    {title}
                </h1>
            </div>
        </section>
    )
}
import { userConstants } from "../constant";
import { userService } from "../services";

export const signupEmailVerificationAction = {
  emailVerify,
};

function emailVerify(otp) {
  return (dispatch) => {
    dispatch(request());
    return userService
      .registerVerificationOtp(otp)
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response.data));
          if (response.message === "Email verified Successfully!") {
            window.location.reload();
          }
        } else {
          dispatch(failure(response));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: userConstants.REQUEST_EMAIL_OTP, user };
  }
  function success(status) {
    return { type: userConstants.RECEIVE_EMAIL_OTP, status };
  }
  function failure(error) {
    return { type: userConstants.ERROR_EMAIL_OTP, error };
  }
}

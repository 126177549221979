import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../Common/CourseCard";

function Suggestions({ data, course_id }) {
  const { t, i18n } = useTranslation();
  return (
    <section className="mb_50">
      {data?.length > 1 && (
        <div className="container" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
          <div className="section_title mb_40">
            <div className="title_wrapper flx_hcenter">
              <h3>{t('common.suggestionsYouLike')}</h3>
            </div>
          </div>
          <div className="row">
            {data
              ?.filter((item) => item?.id != course_id)
              .map((course, index) => {
                return (
                  <Card
                    key={index}
                    id={course.id}
                    title={course.name}
                    tutuor={`${course.instructor.first_name} ${course.instructor.last_name}`}
                    price={course.is_free === 0 ? course.course_sale : "Free"}
                    thumbnail={course.course_image}
                    isFree={course?.is_free}
                    data={course}
                    isPurchased={course?.is_purchased}
                    translation={course?.translation}
                    instructorsList={course?.instructors}
                  />
                );
              })}
          </div>
        </div>
      )}{" "}
    </section>
  );
}

export default Suggestions;

import { userConstants } from "../constant";

const initialState = {
  loading: false,
  error: false,
  status: [],
};

export default function resendOtp(state = initialState, action) {
  switch (action.type) {
    case userConstants.RESEND_OTP:
      return {
        loading: true,
        error: false,
        status: [],
      };
    case userConstants.RESEND_OTP_SUCCESS:
      return {
        loading: false,
        error: false,
        status: action.status,
      };
    case userConstants.RESEND_OTP_FAILURE:
      return {
        error: true,
        loading: false,
        status: action.error,
      };
    case userConstants.CLEAR_RESEND_OTP_STATE:
      return {
        error: false,
        loading: false,
        status: []
      }
    default:
      return state;
  }
}

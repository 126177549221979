import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Pagination from '../Pagination';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = (props) => {
    const { videoUrl, isDownloadable } = props;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(pageNumber => pageNumber + offset);
    }
      
    function previousPage() {
        changePage(-1);
    }
      
    function nextPage() {
        changePage(1);
    }

    function goToPage(pageno) {
        setPageNumber(pageno);
    }

    return (
        <div className="course_video p-3">
            <Document
                className="overflow-auto"
                file={process.env.REACT_APP_ASSETS + '/api/proxy' + '?videoURL=' + videoUrl //process.env.REACT_APP_ASSETS + videoUrl,
            }
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
            <div className="buttonc">
                <button
                type="button"
                className="btn btn_secondary"
                disabled={pageNumber <= 1}
                onClick={previousPage}                    
                >
                Previous
                </button>
                <Pagination totalPages={numPages} currentPage={pageNumber} goToPage={goToPage}/>
                <button
                type="button"
                className="btn btn_primary pdf-btn-secondary"
                disabled={pageNumber >= numPages}
                onClick={nextPage}                
                >
                Next
                </button>
                {isDownloadable ? (
                    <a className="btn btn_secondary ml-3" href={process.env.REACT_APP_ASSETS + videoUrl} target="_blank" download>Download</a>
                ) : ""}
            </div>
        </div>
    )
}

export default PDFReader;

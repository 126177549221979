import "../../assets/styles/chatbot.css"

import { useState, useEffect, useContext, useRef } from 'react'
import { getStudentThreads, putStudentThreads } from '../services/student'
import { UserContext } from "../context/AuthContext";
import { useModal } from "react-modal-hook";
import ReportIssueModal from "../modals/ReportIssueModal";
import ReactModal from "react-modal";

import { OpenAI } from 'openai'

import { IoMdSend } from "react-icons/io";
import logo from '../../assets/image/logo_resized.png'
import {
    MdOutlineReportProblem
} from "react-icons/md";

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
})

const assistant = await openai.beta.assistants.retrieve(process.env.REACT_APP_TUTOR_ID)

function Response(props)
{
    const { text, person, user_id, course_id} = props

    const [show, setShow] = useState(false);

    const handleReportIssue = () => {
        openReportIssueModal();
    };

    const [openReportIssueModal, closeReportIssueModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal
                isOpen
                ariaHideApp={false}
                className="react_modal"
                overlayClassName="modal_overlay"
            >
                <ReportIssueModal
                    closeModal={closeReportIssueModal}
                    questionInfo={{
                        course_id: course_id,
                        chapter_id: null,
                        user_id: user_id,
                        response: text
                    }}
                    setShow={setShow}
                    type="ai_tutor"
                />
            </ReactModal>
        );
    });

    return (
        <div className="response-container">
            <span className="conversation-text" style={person == 'student' ? {color: 'white'} : {}}>{text}</span>
            {text != '...' && person == 'assistant' && text.includes('How can I help you with') == false ?
                <button
                onClick={() => handleReportIssue()}
                style={{
                    color: "#0b4475",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    fontSize: "24px",
                }}
                >
                    <span>
                        <MdOutlineReportProblem />
                    </span>
                </button>
            :
                null
            }
        </div>
    )

}

function Tutor(props)
{
    const { course, course_id } = props
    const { user } = useContext(UserContext);

    let chatboxRef = useRef()

    const [conversation, setConversation] = useState([
        {
            person: 'assistant',
            text: `How can I help you with ${course}?`
        }
    ])
    const [studentInput, setStudentInput] = useState('')
    const [threadID, setThreadID] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getThreads()
        .then((res) => {
          // check if user has a thread
          if (res.data[0].tutor_thread == ' '){
            openai.beta.threads.create()
              .then(res => {
                updateThreads(res.id)
                setThreadID(res.id)
              })
              .catch(err => console.log(err))
          }else{
            setThreadID(res.data[0].tutor_thread)
          }
        })
        .catch((err) => console.log(err))
    }, [user])

    async function getThreads()
    {
        const resp = await getStudentThreads(user?.id);
        if (resp.success)
        {
            return resp
        } else 
        {
            throw new Error('Could not fetch threads');
        }
    }

    async function updateThreads(thread_id)
    {
        const resp = await putStudentThreads(user?.id, {
          thread_id: thread_id,
          to_update: 'tutor_thread'
        });
        if (resp.success) {
          return resp
        } else {
        //   console.log(resp)
          throw new Error('Could not update threads');
        }
    }

    function handleInputChange(e)
    {
        setStudentInput(e.target.value)
    }

    function submitStudentInput()
    {
        if (studentInput != '')
        {
            conversation.push({
                person: 'student',
                text: studentInput
            })
            createMessage()
            setStudentInput('')
            setTimeout(() => chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight, 100)
            setLoading(true)
            conversation.push({
                person: 'assistant',
                text: '...'
            })
            setTimeout(() => chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight, 100)
        }
    }

    async function createMessage(){
        openai.beta.threads.runs.list(threadID)
        .then(res1 => {
            if (res1.body.first_id == null){
            openai.beta.threads.messages.create(threadID, {
                role: "user",
                content: `I have a question: ${studentInput}. Please use the documents related to ${course} to find the answer. If this question is not related
                to ${course}, then please respond that this question is not related to ${course}`,
            })
                .then((res) => {
                    runAssistant()
                    setLoading(true)
                })
                .catch(err => console.log(err))
            } else {
                openai.beta.threads.runs.retrieve(threadID, res1.body.first_id)
                    .then(res => {
                        if (res.status == 'completed' || res.status == 'expired' || res.status == 'failed' || res.status == 'incomplete') {
                            if (res.last_error.code == 'rate_limit_exceeded')
                            {
                                conversation.pop()
                                setConversation(prevConvo => [...prevConvo, {
                                    person: 'assistant',
                                    text: 'AI features are temporarily disabled, please contact support.'
                                }])
                                setLoading(false)
                            } 
                            else
                            {
                                openai.beta.threads.messages.create(threadID, {
                                    role: "user",
                                    content: `I have a question: ${studentInput}. Please use the documents related to ${course} to find the answer. If this question is not related
                                    to ${course}, then please respond that this question is not related to ${course}`,
                                })
                                .then((res) => {
                                    runAssistant()
                                    setLoading(true)
                                })
                                .catch(err => console.log(err))
                            }
                        } else {
                            // console.log(res)
                        }
                    })
                    .catch(err => console.log('error'))
            }
    
        })
        .catch(err => console.log('error'))
    }

    async function runAssistant(){
        setLoading(true)
        openai.beta.threads.runs.create(threadID, {
            assistant_id: assistant.id
        })
        .then((res) => {
        setLoading(true)
        retrieveRun(res.id)
        })
    }
        
    function retrieveRun(run_id){
        openai.beta.threads.runs.retrieve(threadID, run_id)
        .then(res => {
            if (res.status == 'in_progress' || res.status == 'queued'){
                setLoading(true)
                setTimeout(() => {
                    retrieveRun(run_id)
                    console.log('running')
                }, 4000)
            } else if (res.status == 'completed'){
                getMessages()
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    async function getMessages()
    {
        openai.beta.threads.messages.list(threadID)
            .then(res => {
                let response = res.data[0].content[0].text.value
                let role = res.data[0].role
                const regex = /【[^】]*】/g;

                if (response.includes('【'))
                {
                    response = response.replace(regex, '')
                }

                if (response.includes("the document"))
                {
                    response = response.replace("the document", "Dawraty")
                } else if (response.includes("The document"))
                {
                    response = response.replace("The document", "Dawraty")
                }

                if (response.includes('**'))
                {
                    response = response.replace(/\*\*/g, "")
                }

                conversation.pop()
                setConversation(prevConvo => [...prevConvo, {
                    person: role,
                    text: response
                }])
                setTimeout(() => chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight, 100)
            })
            .catch(err => console.log(err))
    }
    
    return (
        <div className="chatbot">

            <div ref={chatboxRef} className="chatbot-dialog">
                {conversation.map((query, index) => 
                    <div className="conversation-component" style={query.person == 'student' ? {background: '#0b4475'} : {border: '1px solid grey'}}>
                        {query.person == 'assistant' ? <img src={logo} className="conversation-ai-img"/> : null}
                        {query.text == '...' ? 
                            <span class="ellipsis-anim"><span>.</span><span>.</span><span>.</span></span>

                        :
                            <Response person={query.person} text={query.text} user_id={user?.id} course_id={course_id}/>
                        }
                        
                    </div>
                )}
            </div>

            <div className="chatbot-input">
                <div className="chatbot-bottom-bar">
                    <input 
                        value={studentInput} 
                        onKeyDown={(e) => {if (e.key == 'Enter'){submitStudentInput()}}} 
                        onChange={(e) => handleInputChange(e)} 
                        placeholder={loading || threadID == '' ? '' : 'Ask a Question!'}
                        className="chatbot-input-text" 
                        type="text"
                        disabled={loading || threadID == ''}
                        style={loading || threadID == '' ? {backgroundColor: '#b8b8b8'} : {}}
                    />
                    <IoMdSend className="chatbot-input-submit" style={loading || threadID == '' ? {color: '#b8b8b8'} : {}} onClick={submitStudentInput}/>
                </div>
            </div>
        </div>
    )
}

export default Tutor
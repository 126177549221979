import React, { useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { userActions } from "../../containers/user/actions/me";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../components/context/AuthContext";
import { logoutUser } from "../navigation/navbar/helper";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const { user, loggedIn } = useAuthState();
  const { loggedIn } = useContext(UserContext);
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getUser());
  }, []);

  useEffect(() => {
    if(me.error) {
      logoutUser()
      window.location.href = "/";
    }
  }, [me.error])

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;

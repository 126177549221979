import { getAuthDataCall, postAuthDataCall } from "../utils";

export const getInstructorDashboardData = async () => {
  const resp = await getAuthDataCall(`/v1/instructor_dashboard`);
  return resp;
};

export const getRecentOrders = async (query) => {
  const resp = await getAuthDataCall(`/v1/instructor_course_sales${query}`);
  return resp;
};

export const getIncomeHistory = async (query) => {
  const resp = await getAuthDataCall(`/v1/income_history${query}`);
  return resp;
};

export const getInstructorCourses = async () => {
  const resp = await getAuthDataCall(`/v1/course_instructor`);
  return resp;
};

export const getCourseById = async (_id) => {
  const resp = await getAuthDataCall(`/auth/instructorCourse/${_id}`);
  return resp;
};
